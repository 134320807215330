<template>
  <div data-anima="top" id="club-v2" class="club-v2-container" v-if="clubVersion === 'v2'">
    <b-col cols="12" sm="12" md="12" lg="10">
      <b-row v-if="isMobile">
        <div @click="$router.push('/home')" class="btn-back-home">
          <svg width="10" height="18" viewBox="0 0 10 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9 1L1 9L9 17" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
      </b-row>
      <b-row v-else>
        <b-col
          style="padding-left: 0px;"
          cols="12"
          md="9"
        >
          <div class="lesson-title-container fix-padding">
            <LessonTitle/>
            <LessonDownloads 
              v-if="downloadStyleFile === 'menu' || !downloadStyleFile" 
              :downloadStyle="downloadStyleFile" 
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
        ></b-col>
      </b-row>
      <b-row>
        <b-col style="padding-left: 0px; padding-right: 0px;" cols="12" md="9" >
          <Player :isMobile="isMobile"/>
          <div class="fix-padding">
            <LessonTitle :isMobile="isMobile" v-if="isMobile" />
            <LessonDownloads 
              v-if="downloadStyleFile === 'list'"
            />
            <LessonControl :isMobile="isMobile" />  
            <LessonDescription :fromPlayer="false" v-if="getCurrentLesson.mediaType !== 'text'">
              <div class="star-rating-container mb-2">
                <div style="display: flex; flex-wrap: nowrap;">
                  <star-rating
                    :show-rating="false"
                    :star-size="20"
                    :active-color="getMainColor()"
                    :rating="getCurrentLesson.my_rating || 0"
                    @rating-selected="postRating"
                  ></star-rating>
  
                  <span class="pt-1 ml-2">
                    ({{ parseInt(getCurrentLesson.rating || 0) }})
                  </span>
                </div>
              </div>
            </LessonDescription>
            <div v-else class="star-rating-container pt-2 pl-2 mb-2">
              <div style="display: flex; flex-wrap: nowrap;">
                <star-rating
                  :show-rating="false"
                  :star-size="20"
                  :active-color="getMainColor()"
                  :rating="getCurrentLesson.my_rating || 0"
                  @rating-selected="postRating"
                ></star-rating>

                <span class="pt-1 ml-2">
                  ({{ parseInt(getCurrentLesson.rating || 0) }})
                </span>
              </div>
            </div>
          </div>
          <LessonComments v-if="commentLesson" class="fix-padding"/>
        </b-col>
        <b-col cols="12" md="3">
          <LessonList/>
        </b-col>
      </b-row>
    </b-col>
  </div>
  <SiteTemplate v-else>
    <div :class="{ 'container-grid': !isMobile, 'cols-12 md-12': isMobile }">
      <div
        :class="{
          'container-aula': !isMobile,
          'container-aula-mobile': isMobile,
          'nav-close': closeNav,
        }"
      >
        <div class="side-play" v-if="!loadingPlayer" data-anima="top">
          <h3 v-if="isMobile" class="titulo-aula">
            {{ apiCourse.course.title }}
          </h3>
          <h3
            :class="{
              'titulo-aula': !isMobile,
              'titulo-aula-mobile': isMobile,
            }"
          >
            {{ apiLesson.title }}
            <button
              class="viewModuleLesson"
              v-if="permission && !isMobile"
              @click="viewLessonEdit(apiLesson.id)"
            >
              {{ $t("player.edit") }}
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.375 8.75L12.5 5.625L9.375 2.5"
                  stroke="#C4C4C4"
                  stroke-width="1.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M2.5 12.5V8.125C2.5 7.46194 2.76339 6.82606 3.23223 6.35725C3.70107 5.88839 4.33696 5.625 5 5.625H12.5"
                  stroke="#C4C4C4"
                  stroke-width="1.8"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </h3>
          <div
            v-if="
              apiLesson.source !== '' &&
              apiLesson.source !== null &&
              apiLesson.source !== 'https://www.youtube.com/watch?v='
            "
            :class="{
              playerMobile: isMobile,
              playerMobilePlayer: isMobile && apiLesson.mediaType === 'panda',
              'player relativePosicao':
                apiLesson.mediaType !== 'iframe' && !isMobile,
              playerIframe: apiLesson.mediaType === 'iframe' && !isMobile,
              animationPlayer: closeNav,
              isQuizV2: apiLesson.mediaType === 'quiz' && clubVersion === 'v2'
            }"
          >

            <div
              :class="[
              {'video-player-container-light': theme !== 'dark'},
              'video-player-container'
            ]"
              :style="`height: ${isMobile ? '250px;' : 'calc(100vw / 2.66)'}`"
              v-if="
              activePlayer && (
                apiLesson.mediaType === 'audio' || 
                apiLesson.mediaType === 'iframe' || 
                apiLesson.mediaType === 'vimeo' ||
                apiLesson.mediaType === 'youtube' ||
                (apiLesson.mediaType === 'panda' && apiLesson.can_be_displayed)
              )
            "
            >
              <VideoPlayerVimeo
                v-if="apiLesson.mediaType === 'vimeo' && activePlayer === true"
                :videoURL="vimeoVideoUrl"
                :porcentagem="numberLimitAlunoPercentage"
                class="textexx"
                :videoID="vimeoVideoId"
                :isMobile="isMobile"
              />
              <VideoPlayer
                v-if="apiLesson.mediaType === 'youtube'"
                :porcentagem="numberLimitAlunoPercentage"
                :type="apiLesson.mediaType"
                :isMobile="isMobile"
                :apiLesson="apiLesson"
              />
              <VideoPlayerAudio
                v-if="apiLesson.mediaType === 'audio'"
                :porcentagem="numberLimitAlunoPercentage"
                :type="apiLesson.mediaType"
              />
              <VideoPlayerIframe
                v-if="apiLesson.mediaType === 'iframe'"
                :porcentagem="numberLimitAlunoPercentage"
                :source="apiLesson.source"
                :isMobile="isMobile"
                :height="
                  apiLesson.source.match(regex())
                    ? `height: ${apiLesson.source.match(regex())[1]}px;`
                    : null
                "
              />
              <template v-if="apiLesson.mediaType === 'panda' && apiLesson.can_be_displayed">
                <template
                  v-if="(memberPlanStatus !== 'unpaid' && memberPlanStatus !== 'canceled') || !isExceededStorage">
                  <VideoPlayerPanda
                    :porcentagem="numberLimitAlunoPercentage"
                    :source="apiLesson.source"
                    :isMobile="isMobile"
                  />
                </template>
                <div
                  v-else
                  :class="[
                    {'error-panda-dark': theme === 'dark'},
                    'd-flex align-items-center justify-content-center'
                  ]"
                >
                  {{
                    userRole === 'admin' ||
                    userRole === 'owner' ||
                    userRole === 'student_moderator'
                      ? $t('player.erro_panda_seller') + ' (' + memberPlanStatus + ')'
                      : $t('player.erro_panda_student')
                  }}
                </div>
              </template>
            </div>

            <div v-if="apiLesson.mediaType === 'pdf'" class="notCompatible">
              <p>A aula em formato PDF não é compatível com a versão 1. Por favor, entre em contato com o produtor.</p>
              <svg xmlns="http://www.w3.org/2000/svg" width="72" height="72" fill="#81858e" viewBox="0 0 256 256"><path d="M128,26A102,102,0,1,0,230,128,102.12,102.12,0,0,0,128,26Zm0,192a90,90,0,1,1,90-90A90.1,90.1,0,0,1,128,218Zm-6-82V80a6,6,0,0,1,12,0v56a6,6,0,0,1-12,0Zm16,36a10,10,0,1,1-10-10A10,10,0,0,1,138,172Z"></path></svg>
            </div>

            <Quiz
              v-if="apiLesson.mediaType === 'quiz' && clubVersion === 'v1'"
              :quizData="quiz"
              :isMobile="isMobile"
              @quizCompleted="quizCompleted"
              @hideBtnConcluir="hideBtnConcluir"
            />
            <QuizV2 
              v-if="apiLesson.mediaType === 'quiz' && clubVersion === 'v2'" 
              :data="quiz"
              @quizCompleted="quizCompleted"
            />
          </div>
          <div
            v-if="apiLesson.mediaType === 'panda' && apiLesson.mediaType.media && apiLesson.media.status !== 'CONVERTED'"
            class="info-panda-is-not-converted"
          >
            <h3 style="margin-bottom: 12px;">Opa!</h3>
            <span>O upload desta aula ainda está sendo processado e estará disponível em breve!</span>
          </div>
          <div
            v-if="apiLesson.mediaType === 'panda' && apiLesson.mediaType.media && apiLesson.media.status === 'FAILED'"
            class="info-panda-is-not-converted"
          >
            <span>Erro de processamento!</span>
          </div>

          <div class="controles-aula">
            <a @click="pulaAulaAnterior()">
              <svg
                width="8"
                height="11"
                viewBox="0 0 8 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.34473 1.47256C7.34473 0.618209 6.34261 0.157298 5.69394 0.713304L1.0366 4.7053C0.570992 5.1044 0.570991 5.82472 1.0366 6.22382L5.69393 10.2158C6.34261 10.7718 7.34473 10.3109 7.34473 9.45656L7.34473 1.47256Z"
                  fill="#00E4A0"
                />
              </svg>
              {{ $t("player.previous") }}
            </a>
            <button
              v-if="showBtnConcluir && !isMobile"
              id="btnConcluir"
              :class="{
                completa: completedLesson === $t('player.concluida'),
                disableBtn: !apiLesson.is_liberated,
              }"
              @click.prevent="completaAula"
            >
              <svg
                class="checkedLesson"
                v-if="completedLesson === $t('player.concluida')"
                width="8"
                height="6"
                viewBox="0 0 8 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.81676 0.173632C7.76382 0.118613 7.70086 0.074947 7.63153 0.0451455C7.56214 0.015344 7.48774 0 7.4126 0C7.33746 0 7.26306 0.015344 7.19367 0.0451455C7.12434 0.074947 7.06138 0.118613 7.00844 0.173632L2.7676 4.5526L0.985881 2.70945C0.930938 2.65468 0.866078 2.61165 0.795003 2.58277C0.723933 2.55389 0.648036 2.53975 0.571656 2.5411C0.495275 2.54245 0.419902 2.55935 0.34984 2.59076C0.279783 2.6221 0.216404 2.66748 0.163328 2.72412C0.110252 2.78077 0.0685211 2.84762 0.0405088 2.92094C0.0125021 2.99425 -0.00123369 3.0725 8.69435e-05 3.15127C0.00140758 3.22999 0.0177618 3.30777 0.0482105 3.37997C0.0786648 3.45222 0.122616 3.51756 0.177559 3.57232L2.36344 5.82637C2.41636 5.88137 2.47932 5.92504 2.54868 5.95486C2.61805 5.98462 2.69246 6 2.7676 6C2.84275 6 2.91714 5.98462 2.98653 5.95486C3.05586 5.92504 3.11882 5.88137 3.17176 5.82637L7.81676 1.03651C7.87454 0.981543 7.92065 0.914832 7.95218 0.840578C7.98372 0.766323 8 0.686134 8 0.605071C8 0.524007 7.98372 0.443824 7.95218 0.36957C7.92065 0.295315 7.87454 0.228604 7.81676 0.173632Z"
                  fill="white"
                />
              </svg>

              {{ completedLesson }}
            </button>
            <a @click="pulaAulaProximo()" class="proxima-aula">
              {{ $t("player.next") }}
              <svg
                width="8"
                height="11"
                viewBox="0 0 8 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.957031 9.45615C0.957031 10.3105 1.95915 10.7714 2.60782 10.2154L7.26515 6.22341C7.73077 5.82431 7.73077 5.10399 7.26516 4.70489L2.60782 0.712896C1.95915 0.156891 0.957031 0.617799 0.957031 1.47215L0.957031 9.45615Z"
                  fill="#00E4A0"
                />
              </svg>
            </a>
          </div>

          <div v-if="isMobile" class="controles-aula-mobile">
            <button
              v-if="showBtnConcluir"
              id="btnConcluir"
              :class="{
                completa: completedLesson === $t('player.concluded2'),
                disableBtn: !apiLesson.is_liberated,
              }"
              @click="(e) => completaAula(e)"
            >
              {{ completedLesson }}
            </button>
          </div>

          <div class="header-descricao">
            <div class="avaliacao">
              <p>{{ $t("player.avaliation") }}</p>
              <div class="star">
                <star-rating
                  :show-rating="false"
                  :star-size="30"
                  active-color="#01CAA6"
                  :rating="apiLesson.my_rating || 0"
                  @rating-selected="postRating"
                ></star-rating>
              </div>
            </div>
          </div>

          <div v-if="apiLesson.attachments">
            <ul class="grid-downloads" :class="{ 'mobile-download': isMobile }">
              <li
                v-for="arquivo in apiLesson.attachments"
                :key="arquivo.id"
                @click.prevent="downloadArquivo($event, arquivo)"
              >
                <p
                  v-if="arquivo.mime === 'audio/mpeg'"
                  class="type"
                  style="color: #ffcb37"
                >
                  MP3
                </p>
                <p
                  v-if="arquivo.mime === 'application/pdf'"
                  class="type"
                  style="color: #ff0c37"
                >
                  PDF
                </p>
                <p
                  v-if="arquivo.mime === 'video/mp4'"
                  class="type"
                  style="color: #000000"
                >
                  PDF
                </p>
                <p
                  v-if="
                    arquivo.mime === 'image/jpeg' ||
                    arquivo.mime === 'image/jpg' ||
                    arquivo.mime === 'image/webp' ||
                    arquivo.mime === 'image/png'
                  "
                  class="type"
                  style="color: #2133d2"
                >
                  IMG
                </p>
                <p
                  v-if="arquivo.mime === 'application/zip'"
                  class="type"
                  style="color: #000000"
                >
                  ZIP
                </p>
                <p
                  v-if="
                    arquivo.mime === 'application/msword' ||
                    arquivo.mime ===
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                  "
                  class="type"
                  style="color: #2133d2"
                >
                  ZIP
                </p>
                <p
                  v-if="
                    arquivo.mime === 'application/vnd.ms-excel' ||
                    arquivo.mime ===
                      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                  "
                  class="type"
                  style="color: #039469"
                >
                  ZIP
                </p>
                <p>{{ arquivo.title }}</p>
                <div class="baixar">
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.5 7.66667C11.5 7.39052 11.2761 7.16667 11 7.16667C10.7239 7.16667 10.5 7.39052 10.5 7.66667H11.5ZM1 9.88889H0.5H1ZM1.5 7.66667C1.5 7.39052 1.27614 7.16667 1 7.16667C0.723858 7.16667 0.5 7.39052 0.5 7.66667H1.5ZM3.57578 4.53534C3.38051 4.34007 3.06393 4.34007 2.86867 4.53534C2.67341 4.7306 2.67341 5.04718 2.86867 5.24244L3.57578 4.53534ZM6 7.66667L5.64645 8.02022C5.84171 8.21548 6.15829 8.21548 6.35355 8.02022L6 7.66667ZM9.13133 5.24244C9.32659 5.04718 9.32659 4.7306 9.13133 4.53534C8.93607 4.34007 8.61949 4.34007 8.42422 4.53534L9.13133 5.24244ZM6.5 1C6.5 0.723858 6.27614 0.5 6 0.5C5.72386 0.5 5.5 0.723858 5.5 1H6.5ZM10.5 7.66667V9.88889H11.5V7.66667H10.5ZM10.5 9.88889C10.5 10.051 10.4356 10.2064 10.321 10.321L11.0281 11.0281C11.3303 10.726 11.5 10.3162 11.5 9.88889H10.5ZM10.321 10.321C10.2064 10.4356 10.051 10.5 9.88889 10.5V11.5C10.3162 11.5 10.726 11.3303 11.0281 11.0281L10.321 10.321ZM9.88889 10.5H2.11111V11.5H9.88889V10.5ZM2.11111 10.5C1.94903 10.5 1.7936 10.4356 1.67899 10.321L0.971883 11.0281C1.27403 11.3303 1.68382 11.5 2.11111 11.5V10.5ZM1.67899 10.321C1.56438 10.2064 1.5 10.051 1.5 9.88889H0.5C0.5 10.3162 0.669742 10.726 0.971883 11.0281L1.67899 10.321ZM1.5 9.88889V7.66667H0.5V9.88889H1.5ZM2.86867 5.24244L5.64645 8.02022L6.35355 7.31311L3.57578 4.53534L2.86867 5.24244ZM6.35355 8.02022L9.13133 5.24244L8.42422 4.53534L5.64645 7.31311L6.35355 8.02022ZM6.5 7.66667V1H5.5V7.66667H6.5Z"
                      fill="#333333"
                    />
                  </svg>
                  <p>{{ $t("player.download") }}</p>
                </div>
              </li>
            </ul>
          </div>

          <div :class="{ descricao: !isMobile, 'descricao-mobile': isMobile }">
            <render-description :contentHtml="contentLesson"></render-description>
          </div>
          <div>
    <div v-if="!disable_comments">
      <div v-if="commentLesson && !isMobile" class="comentarios">
        <h5>{{ $t("player.comentario") }}</h5>
        <div class="meu-perfil">
          <div v-if="picProfile === null" class="foto"></div>
          <div v-else class="logox">
            <img :src="picProfile"/>
          </div>
          <div class="item">
            <textarea
              v-model="meuComentario"
              v-on:keyup.enter="comentar"
              :placeholder="$t('player.placeholder_comentario')"
              id="myComment"
              type="text"
            ></textarea>
            <button
              v-if="textBtnComentar === this.$t('player.atualizar')"
              class="cancelaBtn"
              @click="cancelaComment"
            >
              {{ $t("player.cancelar") }}
            </button>
            <button @click.prevent="comentar" :disabled="btnOk">
              {{ textBtnComentar }}
            </button>
          </div>
        </div>
        <div @scroll="handleScroll" class="scroll-infinity"> <!-- Adicione um estilo de altura e overflow para habilitar o scroll -->
          <div v-if="comentarios">
            <div v-for="c in comentarios" :key="c.id" :class="`comment-principal-id-${c.id}`">
              <!-- Renderize seus comentários aqui -->
              <TreeComentarios
                :label="c.comment"
                :autor="c.member"
                :criacao="c.created_at"
                :isMine="c.is_mine"
                :status="c.status"
                :liked="c.liked"
                :likes_count="c.likes_count"
                :id="c.id"
                :nodes="c.replies"
                :depth="0"
              />
            </div>
            <transition name="fade">
              <div class="d-flex justify-content-center mt-5" v-if="loadingComment">
              <b-spinner label="Loading..."></b-spinner>
            </div>
              </transition>
          </div>
        </div>
      </div>
      <div v-else>
        <p class="desativeComents"></p>
      </div>
    </div>
  </div>

          <b-tabs content-class="mt-3" v-if="isMobile && !disable_comments">
            <b-tab :title="$t('search_modal.text6')" active>
              <div class="spaceDataMobile">
                <div class="progresso">
                  <span>{{ totalPorcentagem }}%</span>
                  <b-progress
                    :value="totalPorcentagem"
                    :max="100"
                    class="mb-3 customBar"
                  ></b-progress>
                </div>
              </div>
              <div class="modulo_aula telegramLink" v-if="showTelegramLink">
                <img
                  @click="openTelegramLink"
                  height="40"
                  width="40"
                  src="@/assets/img/telegram.png"
                  alt="telegram-logo"
                />
                <div
                  class="spaceTitleModule"
                  style="flex-direction: column; margin-top: 12px"
                >
                  <!-- <span class="titleModule">title</span> -->
                  <h6 class="titleModule">Grupo no Telegram</h6>
                  <a
                    v-if="telegramLink"
                    class="moduleLiberation"
                    :href="telegramLink"
                    target="_blank"
                  >Clique aqui para entrar no grupo do telegram</a
                  >
                  <a v-else class="moduleLiberation"
                  >Ainda não foi liberado para entrada</a
                  >
                </div>
              </div>
              <div
                class="nav-aulas-mobile"
                :class="{ animationPlayer: closeNav }"
              >
                <div class="listagem-curso">
                  <div
                    class="modulo_aula"
                    v-for="(item, index) in apiCourse.modules"
                    :key="item.id"
                    :class="{
                      is_block: !item.is_liberated,
                      animationOpacity: closeNav,
                      hide: !item.should_show,
                    }"
                  >
                    <!-- true ou false na validação acima -->
                    <div
                      class="spaceTitleModule"
                      @click="toggleModule(item, index, true)"
                    >
                      <router-link
                        class="titleModule"
                        :disabled="!whateverActivatesThisLink"
                        :event="whateverActivatesThisLink ? 'click' : ''"
                        :to="{
                          name: 'modulo',
                          params: { modulo: item.id },
                        }"
                      >
                        <h6>
                          {{ item.title }}
                        </h6>
                        <span
                          v-if="item.is_liberated && item.liberated_at"
                          class="moduleLiberation"
                        >
                          {{ $t("player.liberada") }}
                          {{ item.liberated_at | moment("DD/MM/YYYY") }}
                        </span>
                        <span
                          v-else-if="
                            !item.is_liberated &&
                            isValidDate(item.liberated_until)
                          "
                          class="moduleLiberation"
                        >
                          {{ $t("player.bloqueada") }}
                          {{ item.liberated_until | moment("DD/MM/YYYY") }}
                        </span>
                        <span
                          v-else-if="
                            !item.is_liberated &&
                            !isValidDate(item.liberated_at)
                          "
                          class="moduleLiberation"
                        >
                          {{ $t("player.libera") }}
                          {{ item.liberated_at | moment("DD/MM/YYYY") }}
                        </span>
                      </router-link>
                      <svg
                        class="svgTodoList"
                        :class="{ rotateSvg: item.listModule === true }"
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1 1L7 7L13 1"
                          stroke="#81858E"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <ul
                      v-if="loadingModule === null"
                      :class="{
                        hide: item.id !== currentModule.id,
                        animationOpacity: item.id === currentModule.id,
                      }"
                    >
                      <li
                        v-for="(aulas, index) in currentModuleLessons"
                        :key="aulas.id"
                        :class="{ lessonCompleta: aulas.is_completed }"
                      >
                        <span
                          v-if="
                            currentModuleLessons[index - 1] === undefined &&
                            aulas.small_category !== undefined
                          "
                          class="textSubcategory"
                        >
                          {{ aulas.small_category }}
                        </span>
                        <span
                          v-else-if="
                            currentModuleLessons[index - 1].small_category !==
                            aulas.small_category
                          "
                          class="textSubcategory"
                        >
                          {{ aulas.small_category }}
                        </span>
                        <span @click="openNewLessonIds(item.id, aulas.id)">
                          <router-link
                            :disabled="!whateverActivatesThisLink"
                            :event="whateverActivatesThisLink ? 'click' : ''"
                            :to="{
                              name: 'cursos',
                              params: {
                                modulo: aulas.module_id,
                                aula: aulas.id,
                              },
                            }"
                          >
                            <span
                              :class="{
                                marginSubCategory: aulas.small_category,
                              }"
                            >
                              <img
                                v-if="
                                  aulas.custom_thumb !== null &&
                                  aulas.custom_thumb !== ''
                                "
                                :src="aulas.custom_thumb.cdn_url"
                                class="styleThumb"
                              />
                              <svg
                                v-if="aulas.is_liberated && aulas.source !== ''"
                                class="svgConcluido"
                                width="10"
                                height="13"
                                viewBox="0 0 10 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M0.615234 1.93078C0.615234 1.13962 1.49048 0.661779 2.15599 1.08961L9.25064 5.65045C9.86296 6.04409 9.86296 6.93917 9.25064 7.33281L2.15599 11.8937C1.49048 12.3215 0.615234 11.8436 0.615234 11.0525V1.93078Z"
                                  fill="#00E4A0"
                                />
                              </svg>
                              <svg
                                v-else-if="
                                  aulas.is_liberated && aulas.source === ''
                                "
                                class="svgConcluido"
                                width="13"
                                height="13"
                                viewBox="0 0 13 13"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M7.04169 1.08325H3.25002C2.9627 1.08325 2.68715 1.19739 2.48399 1.40055C2.28082 1.60372 2.16669 1.87927 2.16669 2.16659V10.8333C2.16669 11.1206 2.28082 11.3961 2.48399 11.5993C2.68715 11.8025 2.9627 11.9166 3.25002 11.9166H9.75002C10.0373 11.9166 10.3129 11.8025 10.516 11.5993C10.7192 11.3961 10.8334 11.1206 10.8334 10.8333V4.87492L7.04169 1.08325Z"
                                  stroke="#00E4A0"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M7.04169 1.08325V4.87492H10.8334"
                                  stroke="#00E4A0"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                              <img
                                src="@/assets/icons/block-lesson.svg"
                                v-else
                                class="block-lesson"
                              />
                              {{ aulas.title }}
                            </span>
                          </router-link>
                          <span
                            v-if="aulas.is_liberated && aulas.liberated_at"
                            class="moduleLiberation"
                          >
                            {{ $t("player.liberada") }}
                            {{ aulas.liberated_at | moment("DD/MM/YYYY") }}
                          </span>
                          <span
                            v-else-if="
                              !aulas.is_liberated &&
                              isValidDate(aulas.liberated_until)
                            "
                            class="moduleLiberation"
                          >
                            {{ $t("player.bloqueada") }}
                            {{ aulas.liberated_until | moment("DD/MM/YYYY") }}
                          </span>
                          <span
                            v-else-if="
                              !aulas.is_liberated &&
                              isValidDate(aulas.liberated_at)
                            "
                            class="moduleLiberation"
                          >
                            {{ $t("player.libera") }}
                            {{ aulas.liberated_at | moment("DD/MM/YYYY") }}
                          </span>
                        </span>
                      </li>
                    </ul>
                    <!-- Loading -->
                    <div
                      v-if="loadingModule === item.id"
                      class="d-flex justify-content-center mt-5"
                    >
                      <b-spinner label="Loading..."></b-spinner>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab :title="$t('player.comentario')" v-if="commentLesson">
              <div class="comentarios">
                <h5>{{ $t("player.comentario") }}</h5>
                <div class="meu-perfil">
                  <div class="foto"></div>
                  <div class="item">
                    <textarea
                      type="text"
                      id="myComment"
                      :placeholder="$t('player.placeholder_comentario')"
                      v-model="meuComentario"
                      v-on:keyup.enter="comentar"
                    ></textarea>
                    <button
                      v-if="textBtnComentar === $t('player.atualizar')"
                      class="cancelaBtn"
                      @click="cancelaComment"
                    >
                      {{ $t("player.cancelar") }}
                    </button>
                    <button @click.prevent="comentar" :disabled="btnOk">
                      {{ textBtnComentar }}
                    </button>
                  </div>
                </div>
                <div @scroll="handleScroll" class="scroll-infinity">
                  <div v-if="comentarios">
                    <div v-for="c in comentarios" :key="c.id">
                      <TreeComentarios
                        :label="c.comment"
                        :autor="c.member"
                        :criacao="c.created_at"
                        :isMine="c.is_mine"
                        :status="c.status"
                        :liked="c.liked"
                        :likes_count="c.likes_count"
                        :id="c.id"
                        :nodes="c.replies"
                        :depth="0"
                      />
                    </div>
                    <transition name="fade">
                    <div class="d-flex justify-content-center mt-5" v-if="loadingComment">
                    <b-spinner label="Loading..."></b-spinner>
                  </div>
                    </transition>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <!-- Loading Player -->
        <div v-if="loadingPlayer" class="d-flex justify-content-center mt-5">
          <b-spinner label="Loading..."></b-spinner>
        </div>
        <!-- Loading Menu -->
        <div
          v-if="loadingMenu && !isMobile"
          class="d-flex justify-content-center mt-5"
        >
          <b-spinner label="Loading..."></b-spinner>
        </div>

        <div
          v-if="!loadingMenu && !isMobile"
          class="nav-aulas"
          :class="{ animationPlayer: closeNav }"
          data-anima="bottom"
        >
          <div class="header-nav">
            <button @click.prevent="closeNav = !closeNav">
              <img class="ArrowTitle" src="@/assets/icons/arrow-blue.svg"/>
            </button>
            <span>{{ apiCourse.course.title }}</span>
          </div>
          <div class="progresso">
            <span>{{ totalPorcentagem }}%</span>
            <b-progress
              :value="totalPorcentagem"
              :max="100"
              class="mb-3 customBar"
            ></b-progress>
          </div>
          <div
            v-if="
              !permission &&
              apiCourse.course.remaining_days !== null &&
              apiCourse.course.remaining_days !== undefined
            "
            class="progresso"
          >
            <p class="dataRestante">
              {{ apiCourse.course.remaining_days }} {{ $t("player.remaining") }}
            </p>
          </div>
          <div class="modulo_aula telegramLink" v-if="showTelegramLink">
            <img
              @click="openTelegramLink"
              height="40"
              width="40"
              src="@/assets/img/telegram.png"
              alt="telegram-logo"
            />
            <div
              class="spaceTitleModule"
              style="flex-direction: column; margin-top: 12px"
            >
              <!-- <span class="titleModule">title</span> -->
              <h6 class="titleModule">Grupo no Telegram</h6>
              <a
                v-if="telegramLink"
                class="moduleLiberation"
                :href="telegramLink"
                target="_blank"
              >Clique aqui para entrar no grupo do telegram</a
              >
              <a v-else class="moduleLiberation"
              >Ainda não foi liberado para entrada</a
              >
            </div>
          </div>
          <div class="listagem-curso">
            <div
              v-for="(item, index) in apiCourse.modules"
              :key="item.id"
              class="modulo_aula"
              :class="{
                is_block: !item.is_liberated,
                animationOpacity: closeNav,
                hide: !item.should_show,
              }"
            >
              <!-- true ou false na validação acima -->
              <div class="spaceTitleModule" @click="toggleModule(item, index)">
                <router-link
                  class="titleModule"
                  :disabled="!whateverActivatesThisLink"
                  :event="whateverActivatesThisLink ? 'click' : ''"
                  :to="{
                    name: 'modulo',
                    params: { modulo: item.id },
                  }"
                >
                  <div
                    v-if="item.cover && item.cover.cdn_url !== ''"
                    class="d-flex"
                  >
                    <img :src="item.cover.cdn_url" class="styleThumb mr-1"/>
                    <h6 class="mt-2">
                      {{ item.title }}
                    </h6>
                  </div>
                  <h6 v-else>
                    {{ item.title }}
                  </h6>
                  <span
                    v-if="item.is_liberated && item.liberated_at"
                    class="moduleLiberation"
                  >
                    {{ $t("player.liberada") }}
                    {{ item.liberated_at | moment("DD/MM/YYYY") }}
                  </span>
                  <span
                    v-else-if="
                      !item.is_liberated && isValidDate(item.liberated_until)
                    "
                    class="moduleLiberation"
                  >
                    {{ $t("player.bloqueada") }}
                    {{ item.liberated_until | moment("DD/MM/YYYY") }}
                  </span>
                  <span
                    v-else-if="
                      !item.is_liberated && !isValidDate(item.liberated_at)
                    "
                    class="moduleLiberation"
                  >
                    {{ $t("player.libera") }}
                    {{ item.liberated_at | moment("DD/MM/YYYY") }}
                  </span>
                </router-link>
                <svg
                  class="svgTodoList"
                  :class="{ rotateSvg: item.listModule === true }"
                  width="14"
                  height="8"
                  viewBox="0 0 14 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L7 7L13 1"
                    stroke="#81858E"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
              <ul
                v-if="loadingModule === null"
                :class="{
                  hide: item.id !== currentModule.id,
                  animationOpacity: item.id === currentModule.id,
                }"
              >
                <li
                  v-for="(aulas, index) in currentModuleLessons"
                  :key="aulas.id"
                  :class="{ lessonCompleta: aulas.is_completed }"
                >
                  <span
                    v-if="
                      currentModuleLessons[index - 1] === undefined &&
                      aulas.small_category !== undefined
                    "
                    class="textSubcategory"
                  >
                    {{ aulas.small_category }}
                  </span>
                  <span
                    v-else-if="
                      currentModuleLessons[index - 1].small_category !==
                      aulas.small_category
                    "
                    class="textSubcategory"
                  >
                    {{ aulas.small_category }}
                  </span>
                  <span @click="openNewLessonIds(item.id, aulas.id)">
                    <router-link
                      :disabled="!whateverActivatesThisLink"
                      :event="whateverActivatesThisLink ? 'click' : ''"
                      :to="{
                        name: 'cursos',
                        params: { modulo: aulas.module_id, aula: aulas.id },
                      }"
                    >
                      <span
                        :class="{ marginSubCategory: aulas.small_category }"
                      >
                        <img
                          v-if="
                            aulas.custom_thumb !== null &&
                            aulas.custom_thumb !== ''
                          "
                          :src="aulas.custom_thumb.cdn_url"
                          class="styleThumb"
                        />
                        <svg
                          v-if="aulas.is_liberated && aulas.source !== ''"
                          class="svgConcluido"
                          width="10"
                          height="13"
                          viewBox="0 0 10 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.615234 1.93078C0.615234 1.13962 1.49048 0.661779 2.15599 1.08961L9.25064 5.65045C9.86296 6.04409 9.86296 6.93917 9.25064 7.33281L2.15599 11.8937C1.49048 12.3215 0.615234 11.8436 0.615234 11.0525V1.93078Z"
                            fill="#00E4A0"
                          />
                        </svg>
                        <svg
                          v-else-if="aulas.is_liberated && aulas.source === ''"
                          class="svgConcluido"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.04169 1.08325H3.25002C2.9627 1.08325 2.68715 1.19739 2.48399 1.40055C2.28082 1.60372 2.16669 1.87927 2.16669 2.16659V10.8333C2.16669 11.1206 2.28082 11.3961 2.48399 11.5993C2.68715 11.8025 2.9627 11.9166 3.25002 11.9166H9.75002C10.0373 11.9166 10.3129 11.8025 10.516 11.5993C10.7192 11.3961 10.8334 11.1206 10.8334 10.8333V4.87492L7.04169 1.08325Z"
                            stroke="#00E4A0"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                          <path
                            d="M7.04169 1.08325V4.87492H10.8334"
                            stroke="#00E4A0"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                        <img
                          src="@/assets/icons/block-lesson.svg"
                          v-else
                          class="block-lesson"
                        />
                        {{ aulas.title }}
                      </span>
                    </router-link>
                    <span
                      v-if="aulas.is_liberated && aulas.liberated_at"
                      class="moduleLiberation"
                    >
                      {{ $t("player.liberada") }}
                      {{ aulas.liberated_at | moment("DD/MM/YYYY") }}
                    </span>
                    <span
                      v-else-if="
                        !aulas.is_liberated &&
                        isValidDate(aulas.liberated_until)
                      "
                      class="moduleLiberation"
                    >
                      {{ $t("player.bloqueada") }}
                      {{ aulas.liberated_until | moment("DD/MM/YYYY") }}
                    </span>
                    <span
                      v-else-if="
                        !aulas.is_liberated && isValidDate(aulas.liberated_at)
                      "
                      class="moduleLiberation"
                    >
                      {{ $t("player.libera") }}
                      {{ aulas.liberated_at | moment("DD/MM/YYYY") }}
                    </span>
                  </span>
                </li>
              </ul>

              <!-- Loading Module -->
              <div
                v-if="loadingModule === item.id"
                class="d-flex justify-content-center mt-5"
              >
                <b-spinner label="Loading..."></b-spinner>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-liberated-lesson" hide-footer>
      <div class="allContentModal">
        <div>
          <img src="@/assets/icons/blockvideo.svg" alt="certificado"/>
        </div>
        <div class="spaceText1">
          <p class="text1">{{ $t("player.modal_title") }}</p>
        </div>
        <div class="spaceText2">
          <p class="text2">{{ $t("player.modal_subtitle") }}</p>
          <p class="text2">
            {{ $t("player.released_on") }}
            {{ lessonLiberation | moment("DD/MM/YYYY HH:mm") }}
          </p>
        </div>
        <div>
          <button class="button" @click="voltarHome">
            {{ $t("player.voltar") }}
          </button>
        </div>
      </div>
    </b-modal>
    <FooterBase :class="{ spaceBottom: scrollPosition > 280 }" type="home"/>
  </SiteTemplate>
</template>
<script>
import SiteTemplate from "@/templates/SiteTemplate.vue";

import StarRating from "vue-star-rating";

import TelegramService from "@/services/resources/ClassIntegrationService";

const telegramService = TelegramService.build();
import axios from "axios";
import RenderDescription from "@/components/renderDescription.vue";
import TreeComentarios from "@/components/TreeComentarios.vue";
import VideoPlayer from "@/components/VideoPlayerYT.vue";
import VideoPlayerAudio from "@/components/VideoPlayerAudio.vue";
import VideoPlayerVimeo from "@/components/VideoPlayerVimeo.vue";
import VideoPlayerIframe from "@/components/VideoPlayerIframe.vue";
import VideoPlayerPanda from "@/components/VideoPlayerPanda.vue";
import Quiz from "@/components/Quiz.vue";
import Cookies from "js-cookie";
import FooterBase from "@/components/FooterBase.vue";
import notify from "@/services/libs/notificacao";
import debounce from "lodash.debounce";
import moment from "moment";

import Player from "@/components/V2/player/playersType/index.vue";
import LessonEvaluation from "@/components/V2/player/lessonEvaluation/index.vue";
import LessonDownloads from "@/components/V2/player/lessonDownloads/index.vue";
import LessonControl from "@/components/V2/player/lessonControl/index.vue";
import LessonDescription from "@/components/V2/player/lessonDescription/index.vue";
import LessonComments from "@/components/V2/player/comments/index.vue";
import LessonList from "@/components/V2/player/lessonList/index.vue";
import LessonTitle from "@/components/V2/player/lessonTitle/index.vue";
import QuizV2 from "@/components/V2/player/playersType/quizz/index.vue"
import BottomPlayerControl from "@/components/V2/player/bottom-player-control/index.vue";

import MetaService from "@/services/resources/MetaService";
import MemberService from "@/services/resources/MemberService";
import CourseService from "@/services/resources/CourseService";

const serviceMeta = MetaService.build();
const serviceMember = MemberService.build();
const serviceCourse = CourseService.build();

import {mapActions, mapGetters} from 'vuex';

var regex = /height="(\d*)"/;

export default {
  components: {
    SiteTemplate,
    StarRating,
    TreeComentarios,
    VideoPlayer,
    VideoPlayerVimeo,
    FooterBase,
    VideoPlayerIframe,
    VideoPlayerPanda,
    Quiz,
    VideoPlayerAudio,
    RenderDescription,
    Player,
    LessonComments,
    LessonList,
    LessonTitle,
    LessonEvaluation,
    LessonDownloads,
    LessonControl,
    LessonDescription,
    QuizV2,
    BottomPlayerControl
  },
  data() {
    return {

      comentarios: [],
      // page: 1, // Página atual
      // lastPage: 0,
      totalPage:1,
      currentPage:1,
      loadingComment: false, // Indicador de carregamento

      moment: moment,
      telegramLink: {
        // link: true,
        // liberated_ad: true
      },
      showTelegramLink: false,
      saveModule: [],
      loadingPlayer: false,
      loadingMenu: false,
      loadingModule: null,
      cteste: false,
      dados: "a",
      client: {
        width: 0,
      },
      closeNav: false,
      valorProgress: 33.333333333,
      maxProgress: 50,
      comentariosFake: [],
      meuComentario: "",
      textBtnComentar: this.$t("player.comentar"),
      btnOk: true,
      apiCourse: {
        course: {
          title: "",
        },
      },
      apiLesson: [],
      srcAula: "",
      completedLesson: this.$t("player.marcar_concluida"),
      BASE_URL: process.env.VUE_APP_API_HOST + "/course",
      mimeType: {
        image: "image-type",
        text: "text-type",
        audio: "audio-type",
      },
      // comentarios: null,
      luzApagada: false,
      permission: false,
      editCommentId: "",
      nextLesson: "",
      lastLesson: "",
      nextModule: "",
      lastModule: "",
      totalPorcentagem: 0,
      attachmentsLesson: 0,
      togleNavMobile: false,
      lessonLiberation: "",
      picProfile: "",
      commentLesson: true,
      vimeoVideoId: null,
      vimeoVideoUrl: undefined,
      whateverActivatesThisLink: "",
      currentModule: [],
      currentModuleLessons: [],
      blockLinks: false,
      numberLimitAlunoPercentage: 0,
      lastModuleLesson: [],
      completeLessonAut: false,
      scrollPosition: null,
      showBtnConcluir: true,
      quiz: [],
      activePlayer: false,
      memberPlanStatus: 'paid',
      disable_comments: false,
      savedParams:{},
    };
  },
  computed: {
    ...mapGetters(['getCurrentLesson']),
    downloadStyleFile() {
      return this.$store.state.metas.metas.download_style_file;
    },
    isExceededStorage() {
      return Cookies.get('exceeded_storage') ? JSON.parse(Cookies.get('exceeded_storage')) : false
    },
    userRole() {
      const typeView = Cookies.get("type_student");
      if (typeView === '1') return 'student'
      if (typeView === '0') return 'owner'
      return this.$store.getters['currentSite'].currentMember.role
    },
    isMobile() {
      return this.client.width <= 768;
    },
    contentLesson() {
      let content = this.apiLesson.content;
      let newContent = this.apiLesson.content;
      if (content) {
        newContent = content.replaceAll("<pre", "<p");
        newContent = newContent.replaceAll("</pre>", "</p>");
      }
      return newContent;
    },
    theme() {
      return Cookies.get("themeClub");
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  async created() {
    this.$store.commit('REMOVE_META', 'download_style_file');
    await this.fetchMetas(["download_style_file"]);
  },
  methods: {
    ...mapActions(["loadPlayerFromUrl", "setIsMobile", "fetchMetas"]),
    getMainColor() {
      let mainColor = "";
      const element = document.getElementById('club-v2');
      if (element) {
        const style = getComputedStyle(element);
        mainColor = style.getPropertyValue('--maincolor');
      }
      return mainColor;
    },
    insertTheme() {
      const d = document;
      let theme = Cookies.get('themeClub') ? Cookies.get('themeClub') : 'light';
      if (theme === undefined || theme === null) {
        d.documentElement.setAttribute('data-theme', 'light');
        if (document.getElementById("slider")) {
          document.getElementById("slider").checked = false;
        }
      } else {
        d.documentElement.setAttribute('data-theme', theme);
        if (theme === "light") {
          if (document.getElementById("slider")) {
            document.getElementById("slider").checked = false;
          }
        } else {
          if (document.getElementById("slider")) {
            document.getElementById("slider").checked = true;
          }
        }
      }
      this.$root.$emit('loadTheme');
      this.$root.$emit('loadsettheme');
    },
    getThemeFixed() {
      serviceMeta
        .search('keys[]=theme_fixed_painel')
        .then((resp) => {
          if (resp.theme_fixed_painel === 'on') {
            this.getThemeFixedColor();
          } else {
            this.insertTheme();
          }
        })
        .catch((err) => console.error(err));
    },
    getThemeFixedColor() {
      serviceMeta
        .search('keys[]=theme_fixed_painel_color')
        .then((resp) => {
          let theme = 'light';
          if (resp.theme_fixed_painel_color === 'light') {
            Cookies.set('themeClub', 'light', { expires: 365 });
            theme = 'light';
          } else {
            Cookies.set('themeClub', 'dark', { expires: 365 });
            theme = 'dark';
          }

          document.documentElement.setAttribute('data-theme', theme);
          this.$root.$emit('loadTheme');
          this.$root.$emit('loadsettheme');
        })
        .catch((err) => console.error(err));
    },
    scrollToComment() {
      // console.log('container on')
      if (this.$route.query.c_id) {
        var container = this.$el.querySelector(`.comment-principal-id-${this.$route.query.c_id}`);
        if (container.offsetTop) {
          container.scrollTop = container.offsetTop;
          window.scrollTo(0, container.offsetTop);
        }
      }
    },
    openTelegramLink() {
      window.open(this.telegramLink, "_blank");
    },
    regex() {
      return regex;
    },
    hideBtnConcluir() {
      this.showBtnConcluir = false;
    },
    quizCompleted(concluida = true, text = this.$t("player.concluida")) {
      this.completedLesson = text;
      this.completeLessonAut = concluida;
    },
    isValidDate(date) {
      var dataAtual = moment();
      var dataVencimento = moment(date);
      return dataAtual.isAfter(dataVencimento, "day");
    },
    async openNewLessonIds(module_id, lesson_id) {
      this.showBtnConcluir = true;
      //verifica e busca aula clicada
      var idCourse = parseInt(this.$route.params.curso, 10);
      var lessonAtual = parseInt(this.$route.params.aula, 10);
      let currentModuleLessons = this.currentModuleLessons;
       this.savedParams = {
          idCourse: idCourse,
          idModule: module_id,
          idLesson: lesson_id
        }
      this.comentarios = [];
      this.$router.push(
        "/curso/" + idCourse + "/modulo/" + module_id + "/aula/" + lesson_id
      );
      this.getNextAndPrev();
      if (this.blockLinks && this.apiLesson.mediaType !== "audio") {
        var orderLesson = null;
        for (let i = 0; i < currentModuleLessons.length; i++) {
          const lesson = currentModuleLessons[i];
          if (lesson_id === lesson.id) {
            orderLesson = i;
          }
        }

        for (let x = 0; x < currentModuleLessons.length; x++) {
          const allLesson = currentModuleLessons[x];
          if (
            lessonAtual === allLesson.id &&
            x < orderLesson &&
            allLesson.is_completed === false
          ) {
            // notify("erro", this.$t("player.errors.msg1"));
            return;
          }
        }
      }

      // this.$router.push(
      //   "/curso/" + idCourse + "/modulo/" + module_id + "/aula/" + lesson_id
      // );
      this.activePlayer = false;
      await this.getLesson();
    },
    async getLessonSave(lesson) {
      this.showBtnConcluir = true;
      await this.getPermission();
      this.apiLesson = lesson;
      this.srcAula = lesson.source;
      this.attachmentsLesson = lesson.attachments.length;
      this.activePlayer = false;
      if (this.apiLesson.is_liberated === false) {
        this.lessonLiberation = this.apiLesson.liberated_at;
        if (
          this.apiLesson.mediaType === "youtube" ||
          this.apiLesson.mediaType === "audio"
        ) {
          this.$root.$emit("dataLesson", null);
        } else {
          this.vimeoVideoId = null;
        }
      } else {
        if (
          this.apiLesson.mediaType === "youtube" ||
          this.apiLesson.mediaType === "audio"
        ) {
          setTimeout(() => {
            this.$root.$emit("dataLesson", this.apiLesson);
          }, 1000);
        }
        if (this.apiLesson.mediaType === "vimeo") {
          this.setRegexVimeo();
        }
      }

      if (this.apiLesson.is_completed) {
        this.completedLesson = this.$t("player.concluida");
      } else {
        this.completedLesson = this.$t("player.marcar_concluida");
      }
      this.calculaPorcentagem();
      this.getNextAndPrev();
      this.activePlayer = true;
    },
    viewLessonEdit(lesson_id) {
      this.showBtnConcluir = true;

      // redireciona para o adm editar a aula
      let routeData = this.$router.resolve(
        "/config_curso/" +
        this.apiLesson.course_id +
        "/module/" +
        this.apiLesson.module_id +
        "/lesson?lesson_id=" +
        lesson_id
      );
      window.open(routeData.href, "_blank");
    },
    async getMetaComent() {
      // verifica se os comentarios estão ativos
     await serviceMeta
        .search("keys[]=coment_lesson")
        .then((resp) => {
          var data = resp.coment_lesson;
          if (data === "off") {
            this.commentLesson = false;
          } else {
            this.commentLesson = true;
          }
        })
        .catch((err) => console.error(err));
    },
    getMetaDisableCommentsCourses() {
      // verifica se os comentarios estão desabilitados ou não
      serviceMeta
        .search("keys[]=disable_comments_courses")
        .then(resp => {
          if (resp.disable_comments_courses && typeof resp.disable_comments_courses === 'string') {
            const disable_comments_courses = JSON.parse(resp.disable_comments_courses)
            this.disable_comments = disable_comments_courses.some(x => x.id === parseInt(this.$route.params.curso)) ? true : false
          }
        })
        .catch((err) => console.error(err))
    },
    getPicProfile() {
      // busca foto de perfil para comentario
      serviceMember
        .read("/meta")
        .then((resp) => {
          this.picProfile = resp.picture;
        })
        .catch((err) => console.error(err));
    },
    voltarHome() {
      this.$router.push("/home");
    },
    toggleModule(itemReceived, prevIndexModule) {
      let internalItem = itemReceived;
      this.activePlayer = false;
      this.loadingModule = null;
      // troca de modulo
      let idCourse = this.$route.params.curso;
      let idModule = this.$route.params.modulo;

      if (this.blockLinks) {
        // let currentModuleLessons = this.currentModuleLessons;
        let AllModules = this.apiCourse.modules;

        let orderActualModule = null;

        const filterActualModuleIndex = (modules) => {
          let moduleIndex = false;
          modules.map((module, index) => {
            if (module.id === parseInt(idModule)) {
              moduleIndex = index;
            }
          });
          return moduleIndex;
        }
        orderActualModule = filterActualModuleIndex(AllModules);

        const prevModule = AllModules[prevIndexModule];
        const actualModule = AllModules[orderActualModule];
        // console.log('actual module', actualModule);
        // console.log('actual module', orderActualModule);


        if (prevIndexModule > orderActualModule) {
          if (actualModule) {
            if (!actualModule.is_completed) {
              notify("erro", "Você tem aulas pendentes, termine antes de passar para os próximos módulos");
              return;
            }
          }
        } else {
          internalItem = prevModule
          this.currentModule = prevModule;
        }
      }

      this.loadingModule = internalItem.id;

      if (!this.saveModule[internalItem.id]) {
        this.currentModule = internalItem;
      } else {
        this.currentModule = internalItem;
        this.currentModuleLessons = this.saveModule[internalItem.id];
        this.activePlayer = true;
        this.loadingModule = null;
        return;
      }

      serviceCourse
        .read(
          `/${idCourse}/watch?data[]=currentModuleLessons&current_module_id=` +
          internalItem.id
        )
        .then((resp) => {
          this.currentModuleLessons = resp.currentModuleLessons;
          this.saveModule[internalItem.id] = resp.currentModuleLessons;
        })
        .catch((err) => console.log(err))
        .finally(() => {
          this.activePlayer = true;
          this.loadingModule = null;
        });
    },
    getCurrentModuleId() {
      let idCourse = this.$route.params.curso;
      let idModule = this.$route.params.modulo;
      serviceCourse
        .read(
          `/${idCourse}/watch?data[]=currentModule&data[]=currentModuleLessons&current_module_id=` +
          idModule
        )
        .then((resp) => {
          this.currentModule = resp.currentModule;
          this.currentModuleLessons = resp.currentModuleLessons;
          this.saveModule[resp.currentModule.id] = resp.currentModuleLessons;

          this.calculaPorcentagem();
          this.scrollToComment();
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.activePlayer = true;
        });
    },
    handleResize() {
      if (
        (window.matchMedia("(orientation: landscape)").matches &&
          window.outerWidth < 1000) ||
        (window.matchMedia("(orientation: portrait)").matches &&
          window.outerWidth < 769 &&
          window.outerHeight < 1025)
      ) {
        this.client.width = 0;
        this.setIsMobile(true);
      } else {
        this.client.width = window.outerWidth;
        this.setIsMobile(window.outerWidth <= 768 ? true : false);
      }
    },
    async pulaAulaProximo(type) {
      this.showBtnConcluir = true;
      this.activePlayer = false;

      // pula para proxima aula
      const concluded = this.$t("player.concluida");
      if (
        this.blockLinks &&
        type !== "next" &&
        this.completedLesson !== concluded &&
        this.apiLesson.mediaType !== "text" &&
        this.apiLesson.mediaType !== "iframe" &&
        this.completeLessonAut === false
      ) {
        this.$root.$emit("clicouNext", this.apiLesson.mediaType);
      } else {
        if (
          this.completeLessonAut === true &&
          type !== "next" &&
          this.completedLesson !== concluded
        ) {
          if (this.blockLinks) {
            this.$root.$emit("calcula", this.apiLesson.mediaType);
          } else {
            this.actionToCompleteBlock("next");
          }
          return;
        } else if (
          this.blockLinks &&
          this.completeLessonAut === false &&
          type !== "next" &&
          this.completedLesson !== concluded
        ) {
          notify("erro", this.$t("player.errors.msg2"));
          return;
        }
        let idCourse = this.$route.params.curso;
        let idLesson = this.$route.params.aula;

        await this.getNextAndPrev();

        if (idLesson == this.nextLesson || this.nextLesson === "") {
          serviceCourse
            .read(
              `/${idCourse}/watch?data[]=currentModule&data[]=currentModuleLessons&current_module_id=` +
              this.nextModule
            )
            .then((resp) => {
              this.activePlayer = false;
              // console.log("api watch xxxxxxxxxxx2", resp);
              this.currentModule = resp.currentModule;
              this.apiCourse.currentModuleLessons = resp.currentModuleLessons;
              this.currentModuleLessons = resp.currentModuleLessons;
              this.saveModule[resp.currentModule.id] = resp.currentModuleLessons;
              const lessonPath = (resp.currentModuleLessons && resp.currentModuleLessons.length) ? `/aula/${resp.currentModuleLessons[0].id}` : "";
              const lessonData = lessonPath ? resp.currentModuleLessons[0] : {};
              this.$router.push(
                "/curso/" +
                idCourse +
                "/modulo/" +
                resp.currentModule.id +
                lessonPath
              );
              this.apiLesson = lessonData;
              if (lessonPath && this.apiLesson.mediaType === "quiz") {
                this.quiz = [];
                this.quiz = lessonData;
              }

              this.srcAula = lessonData.source;
              this.attachmentsLesson = lessonData.attachments.length;
              if (lessonData.is_liberated === false) {
                this.lessonLiberation = lessonData.liberated_at;
                if (
                  lessonData.mediaType === "youtube" ||
                  lessonData.mediaType === "audio"
                ) {
                  this.$root.$emit("dataLesson", null);
                } else {
                  this.vimeoVideoId = null;
                }
              } else {
                if (
                  lessonData.mediaType === "youtube" ||
                  lessonData.mediaType === "audio"
                ) {
                  this.$root.$emit("dataLesson", this.apiLesson);
                }
                if (lessonData.mediaType === "vimeo") {
                  this.setRegexVimeo();
                }
              }
              if (lessonData.is_completed) {
                this.completedLesson = concluded
              } else {
                const markAsConcluded = this.$t("player.marcar_concluida")
                this.completedLesson = markAsConcluded;
              }
              this.getCurrentModuleId();
            })
            .finally(() => {
              this.activePlayer = true;
            });
        } else {
          this.$router.push(
            "/curso/" +
            idCourse +
            "/modulo/" +
            this.nextModule +
            "/aula/" +
            this.nextLesson
          );
          this.getCurrentModuleId();
          this.getLesson();
        }
      }
    },
    async pulaAulaAnterior() {
      this.showBtnConcluir = true;

      //pula pra aula anterior
      let idCourse = this.$route.params.curso;
      let idLesson = this.$route.params.aula;
      let idModule = this.$route.params.modulo;

      await this.getNextAndPrev();

      if (idLesson == this.lastLesson || this.lastLesson === "") {
        serviceCourse
          .read(
            `/${idCourse}/watch?data[]=currentModule&data[]=currentModuleLessons&current_module_id=` +
            this.lastModule
          )
          .then((resp) => {
            this.activePlayer = false;
            this.currentModule = resp.currentModule;
            this.currentModuleLessons = resp.currentModuleLessons;
            this.apiCourse.currentModuleLessons = resp.currentModuleLessons;
            this.saveModule[resp.currentModule.id] = resp.currentModuleLessons;
            let lastLesson = this.currentModuleLessons.length;
            this.$router.push(
              "/curso/" +
              idCourse +
              "/modulo/" +
              resp.currentModule.id +
              "/aula/" +
              resp.currentModuleLessons[lastLesson - 1].id
            );
            this.apiLesson = resp.currentModuleLessons[lastLesson - 1];
            if (this.apiLesson.mediaType === "quiz") {
              this.quiz = [];
              this.quiz = this.apiLesson;
            }
            this.srcAula = resp.currentModuleLessons[0].source;
            this.attachmentsLesson =
              resp.currentModuleLessons[0].attachments.length;
            if (this.apiLesson.is_liberated === false) {
              this.lessonLiberation = this.apiLesson.liberated_at;
              if (
                this.apiLesson.mediaType === "youtube" ||
                this.apiLesson.mediaType === "audio"
              ) {
                this.$root.$emit("dataLesson", null);
              } else {
                this.vimeoVideoId = null;
              }
            } else {
              if (
                this.apiLesson.mediaType === "youtube" ||
                this.apiLesson.mediaType === "audio"
              ) {
                this.$root.$emit("dataLesson", this.apiLesson);
              }
              if (this.apiLesson.mediaType === "vimeo") {
                this.setRegexVimeo();
              }
            }

            if (this.apiLesson.is_completed) {
              this.completedLesson = this.$t("player.concluida");
            } else {
              this.completedLesson = this.$t("player.marcar_concluida");
            }
            this.calculaPorcentagem();
            this.activePlayer = true;
          })
          .catch((err) => console.error(err))
          .finally(() => {
            this.activePlayer = true;
          });
      } else {
        this.$router.push(
          "/curso/" +
          idCourse +
          "/modulo/" +
          idModule +
          "/aula/" +
          this.lastLesson
        );
        this.getLesson();
      }
    },
    async getPermission() {
      //busca as permições
      let permission = Cookies.get("access_level");
      let typeView = Cookies.get("type_student");
      await this.getOptionsConfig();
      if (permission === "admin" || permission === "owner") {
        if (typeView === 1 || typeView === "1") {
          this.permission = false;
          return;
        }
        this.permission = true;
        // comentar linha a baixo validação para admin n existe
      } else {
        this.permission = false;
      }
    },
    getOptionsConfig() {
      //busca os metas necessarios
      serviceMeta
        .search(
          "keys[]=limit_percetage_lesson&keys[]=number_limit_percetage_lesson&keys[]=complete_lessson_next"
        )
        .then((resp) => {
          //console.log("get limit_percetage_lesson", resp);

          if (
            resp.limit_percetage_lesson === null ||
            resp.limit_percetage_lesson === "off"
          ) {
            this.blockLinks = false;
          } else {
            this.blockLinks = true;
          }

          if (resp.number_limit_percetage_lesson !== null) {
            this.numberLimitAlunoPercentage =
              resp.number_limit_percetage_lesson;
          } else {
            this.numberLimitAlunoPercentage = 0;
          }

          if (
            resp.complete_lessson_next === null ||
            resp.complete_lessson_next === "off"
          ) {
            this.completeLessonAut = false;
          } else {
            this.completeLessonAut = true;
          }
        });
    },
    getIcon(filename) {
      const extension = filename.split("/").shift();
      const result = this.mimeType[extension];
      if (result) return result;
      else return "default-type";
    },
    apagarLuz() {
      //modo dark no player
      if (this.luzApagada === false) {
        this.luzApagada = true;
        this.$root.$emit("luzOn");
        this.closeNav = true;
      } else {
        this.$root.$emit("luzOff");
        this.luzApagada = false;
        this.closeNav = false;
      }
    },
    downloadArquivo(e, arquivo) {
      //download dos arquivos
      let params = this.$route.params;
      e.currentTarget.classList.add("baixando");
      axios({
        url:
          process.env.VUE_APP_API_HOST +
          `/course/${params.curso}/module/${params.modulo}/lesson/${params.aula}/attachment/${arquivo.id}/download`,
        method: "GET",
        responseType: "blob",
        headers: {
          Authorization: Cookies.get("auth_greennCourse"),
        },
      }).then((response) => {
        var fileURL = "";
        if (arquivo.mime === "application/pdf") {
          fileURL = window.URL.createObjectURL(
            new Blob([response.data], {type: "application/pdf"})
          );
        } else {
          fileURL = window.URL.createObjectURL(new Blob([response.data]));
        }
        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", arquivo.title);
        document.body.appendChild(fileLink);

        fileLink.click();

        setTimeout(() => {
          const target = document.querySelector(".baixando");
          target.classList.remove("baixando");
          target.classList.add("download-concluido");
        }, 1000);
      });
    },
    changeModal() {
      this.$store.commit("UPDATE_MODAL", true);
    },
    async actionToCompleteBlock(action) {
      //completa aula
      let params = this.$route.params;
      let hasComplete;

      if (this.completedLesson === this.$t("player.marcar_concluida")) {
        hasComplete = true;
      } else {
        hasComplete = false;
      }

      let data = {
        id: `${params.curso}/module/${params.modulo}/lesson/${params.aula}/progress`,
        completed: hasComplete,
      };

      await serviceCourse
        .postID(data)
        .then(async() => {
          await this.getPermission();
          if (hasComplete) {
            this.completedLesson = this.$t("player.concluida");
          } else {
            this.completedLesson = this.$t("player.marcar_concluida");
          }
          if (action === "next") {
            this.pulaAulaProximo("next");
          } else if (action !== "stop") {
            this.pulaAulaProximo();
          }
          this.activePlayer = true;
        })
        .catch(() => {
          this.activePlayer = true;
        });
    },
    completaAula(e) {
      // verifica os dados e completa a aula
      if (this.completedLesson === this.$t("player.concluida")) {
        this.actionToCompleteBlock("stop");
        return;
      }
      if (
        this.blockLinks &&
        this.apiLesson.mediaType !== "text" &&
        this.apiLesson.mediaType !== "audio" &&
        this.apiLesson.mediaType !== "iframe"
      ) {
        this.$root.$emit("clicouConcluir", this.apiLesson.mediaType);
      } else {
        let params = this.$route.params;
        let hasComplete;
        if (e.target.innerText === this.$t("player.marcar_concluida"))
          hasComplete = true;
        else hasComplete = false;
        let data = {
          id: `${params.curso}/module/${params.modulo}/lesson/${params.aula}/progress`,
          completed: hasComplete,
        };
        serviceCourse
          .postID(data)
          .then(() => {
            if (hasComplete) {
              this.completedLesson = this.$t("player.concluida");
            } else {
              this.completedLesson = this.$t("player.marcar_concluida");
            }
            if (this.completeLessonAut === true) {
              this.pulaAulaProximo();
            }
            this.calculaPorcentagem();
            this.getCurrentModule();
            // this.activePlayer = true;
          })
          .catch(() => {
            this.activePlayer = true;
          });
      }
    },
    postRating(rating) {
      //adiciona avaliação na aula
      let params = this.$route.params;
      let data = {
        id: `${params.curso}/module/${params.modulo}/lesson/${params.aula}/rating`,
        rating: rating,
      };
      serviceCourse
        .postID(data)
        .then(() => {
          this.loadPlayerFromUrl();
        })
        .catch((err) => console.error(err));
    },
    async cancelaComment() {
      //fecha area de comentario
      this.meuComentario = "";
      this.textBtnComentar = this.$t("player.comentar");
      await this.getComment();
    },
    async submitComment(comment) {
      let idCourse = this.$route.params.curso;
      let idModule = this.$route.params.modulo;
      let idLesson = this.$route.params.aula;

      let data = {
        id: `/${idCourse}/module/${idModule}/lesson/${idLesson}/comment`,
        comment: comment,
      };
      await serviceCourse
        .postID(data)
        .then(async() => {
          await this.getComment(1, true);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.activePlayer = true;
        });
    },
    async comentar() {
      const comment = this.meuComentario;
      //realiza um comentario
      let idCourse = this.$route.params.curso;
      let idModule = this.$route.params.modulo;
      let idLesson = this.$route.params.aula;

      var data = {};
      if (this.textBtnComentar === this.$t("player.atualizar")) {
        data = {
          id: `/${idCourse}/module/${idModule}/lesson/${idLesson}/comment/${this.editCommentId}`,
          comment: comment || this.meuComentario,
        };
        this.textBtnComentar = this.$t("player.atualizando");
      } else {
        data = {
          id: `/${idCourse}/module/${idModule}/lesson/${idLesson}/comment`,
          comment: comment || this.meuComentario,
        };
        this.textBtnComentar = this.$t("player.comentando");
      }
      await serviceCourse
        .postID(data)
        .then(async() => {
          this.meuComentario = "";
          this.textBtnComentar = this.$t("player.comentar");
          await this.getComment(1, true);
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.activePlayer = true;
        });
    },
    async calculaPorcentagem() {
      this.comentarios = []
      await this.getComment();
      // busca o curso para calcular a porcentagem
      // await this.getCourse();
      let idCourse = this.$route.params.curso;

      await serviceCourse
        .read(`/${idCourse}/progress`)
        .then((resp) => {
          var lessons_completed = resp.lessons_completed;
          var lessons = resp.lessons_count;

          var porcentagem = 0;
          if (lessons_completed !== 0) {
            porcentagem = (lessons_completed / lessons) * 100;
          } else {
            porcentagem = 0;
          }
          var result = parseInt(porcentagem, 10);
          if (result > 100) {
            result = 100;
          }
          this.totalPorcentagem = result;
        })
        .catch((err) => console.log(err));
    },
    verifyModulesOPen() {
      //verifica o modulo q esta aberto
      let idModule = this.$route.params.modulo;
      var resp = this.apiCourse;
      var moduleList = resp.modules;
      for (let i = 0; i < moduleList.length; i++) {
        if (moduleList[i].id == idModule) {
          moduleList[i].listModule = true;
        } else {
          moduleList[i].listModule = false;
        }
      }
      this.apiCourse = resp;
    },
    getCourse() {
      //busca os dados de um curso
      let idCourse = this.$route.params.curso;
      serviceCourse
        .read(`/${idCourse}/watch?data[]=course`)
        .then((resp) => {
          this.apiCourse.course = resp.course;
          this.getNextAndPrev();
        })
        .catch((err) => console.log(err));
    },
    async getCurrentModule() {
      let idCourse = this.$route.params.curso;
      let idModule = this.$route.params.modulo;
      let idLesson = this.$route.params.aula;
      // var options = await this.getOptionsConfig();

      var existModule = "";
      if (idModule !== undefined) {
        existModule = "&current_module_id=" + idModule;
      }

      // busca os dados com base no id do curso
      this.loadingMenu = true;
      this.loadingPlayer = true;
      serviceCourse
        .read(
          `/${idCourse}/watch?data[]=course&data[]=modules&data[]=currentModule&data[]=currentModuleLessons` +
          existModule
        )
        .then((resp) => {
          //console.log("xxxxxxxxxxxxx");
          //console.log(resp);
          //console.log("xxxxxxxxxxxxx");
          this.apiCourse = resp;
          this.currentModule = resp.currentModule;
          this.currentModuleLessons = resp.currentModuleLessons;
          this.saveModule[resp.currentModule.id] = resp.currentModuleLessons;
          this.lastModuleLesson = resp.lastModule;

          //verifica se o modulo antes do current tem todas as aulas completas
          if (resp.lastModule !== null && this.blockLinks === true) {
            let lastModuleLessons = resp.lastModuleLessons;
            for (let i = 0; i < lastModuleLessons.length; i++) {
              const lastLessons = lastModuleLessons[i];
              if (lastLessons.is_completed === false) {
                //console.log(lastLessons, "lastLessons sem concluir");
                this.currentModule = resp.lastModule;
                this.currentModuleLessons = resp.lastModuleLessons;
                this.$router.push(
                  "/curso/" +
                  idCourse +
                  "/modulo/" +
                  lastLessons.module_id +
                  "/aula/" +
                  lastLessons.id
                );
                this.apiLesson = lastLessons;
                if (this.apiLesson.mediaType === "quiz") {
                  this.quiz = this.apiLesson;
                }

                this.srcAula = lastLessons.source;
                this.attachmentsLesson = lastLessons.attachments.length;
                this.ajustaAula();
                this.activePlayer = true;
                this.loadingMenu = false;
                this.loadingPlayer = false;
                return;
              }
            }
          }

          //verifica se existe algum id de aula na url
          if (idModule == this.currentModule.id && idLesson !== undefined) {
            var lessons = this.currentModuleLessons;
            for (let i = 0; i < lessons.length; i++) {
              if (lessons[i].id == idLesson) {
                this.apiLesson = lessons[i];
                if (this.apiLesson.mediaType === "quiz") {
                  this.quiz = this.apiLesson;
                }
                this.srcAula = lessons[i].source;
                this.attachmentsLesson = lessons[i].attachments.length;
              }
            }
            this.calculaPorcentagem();
            this.getLesson();
          } else {
            //se não tiver nenhum id redireciona na primeira aula sem concluir se todas extiverem redireciona para a ultima aula
            let currentModuleLessons = this.currentModuleLessons;
            let allLesson = {};
            for (let i = 0; i < currentModuleLessons.length; i++) {
              allLesson = currentModuleLessons[i];

              if (allLesson.is_completed === false) {
                this.$router.push(
                  "/curso/" +
                  idCourse +
                  "/modulo/" +
                  this.currentModule.id +
                  "/aula/" +
                  this.currentModuleLessons[i].id
                );
                this.apiLesson = resp.currentModuleLessons[i];

                if (this.apiLesson.mediaType === "quiz") {
                  this.quiz = this.apiLesson;
                }

                this.srcAula = resp.currentModuleLessons[i].source;
                this.attachmentsLesson =
                  resp.currentModuleLessons[i].attachments.length;
                this.ajustaAula();
                this.activePlayer = true;
                this.loadingMenu = false;
                this.loadingPlayer = false;
                return;
              }
            }
            if (allLesson.mediaType === "quiz") {
              this.quiz = allLesson;
            }
            this.$router.push(
              "/curso/" +
              idCourse +
              "/modulo/" +
              this.currentModule.id +
              "/aula/" +
              this.currentModuleLessons[currentModuleLessons.length - 1].id
            );
            this.apiLesson =
              resp.currentModuleLessons[currentModuleLessons.length - 1];
            this.srcAula =
              resp.currentModuleLessons[currentModuleLessons.length - 1].source;
            this.attachmentsLesson =
              resp.currentModuleLessons[
              currentModuleLessons.length - 1
                ].attachments.length;
          }
          this.ajustaAula();
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.activePlayer = true;
          this.loadingMenu = false;
          this.loadingPlayer = false;
        });
    },
    ajustaAula() {
      this.activePlayer = false;
      // envia os dados para o player
      if (this.apiLesson.is_liberated === false) {
        this.lessonLiberation = this.apiLesson.liberated_at;
        if (
          this.apiLesson.mediaType === "youtube" ||
          this.apiLesson.mediaType === "audio"
        ) {
          this.$root.$emit("dataLesson", null);
        } else {
          this.vimeoVideoId = null;
        }
      } else {
        if (
          this.apiLesson.mediaType === "youtube" ||
          this.apiLesson.mediaType === "audio"
        ) {
          setTimeout(() => {
            this.$root.$emit("dataLesson", this.apiLesson);
          }, 3000);
        }
        if (this.apiLesson.mediaType === "vimeo") {
          this.setRegexVimeo();
        }
      }

      if (this.apiLesson.mediaType === "text") {
        this.blockLinks = false;
      }

      if (this.apiLesson.is_completed) {
        this.completedLesson = this.$t("player.concluida");
      } else {
        this.completedLesson = this.$t("player.marcar_concluida");
      }
      this.calculaPorcentagem();
      this.getNextAndPrev();
      this.activePlayer = true;
    },
    setRegexVimeo() {
      // var url = this.apiLesson.source;
      // var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)\/([^&#]{5,})/;
      // var match = url.match(regExp);
      // if (match) {
      //   this.vimeoVideoId = match[2] + "/" + match2[3];
      //   this.vimeoVideoUrl = this.apiLesson.source;
      // } else {
      //   var regExp2 = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
      //   var match2 = url.match(regExp2);
      //   if (match2) {
      //     this.vimeoVideoId = match2[2];
      //     this.vimeoVideoUrl = this.apiLesson.source;
      //   }
      // }
      var url = this.apiLesson.source;
      var regExp = /https:\/\/(www\.)?vimeo.com\/(\d+)($|\/)/;
      var match = url.match(regExp);
      if (match) {
        this.vimeoVideoId = match[2];
        this.vimeoVideoUrl = this.apiLesson.source;
      }
    },
    async getNextAndPrev() {
      // calcula proxima aula e a anterior
      let idModule = this.$route.params.modulo;
      let idLesson = this.$route.params.aula;
      var modules = this.apiCourse.modules;
      var lessonsModule = this.apiCourse.currentModuleLessons;

      for (let i = 0; i < lessonsModule.length; i++) {
        const element = lessonsModule[i];
        if (element.id == idLesson) {
          if (lessonsModule[i + 1]) {
            this.nextModule = idModule;
            this.nextLesson = lessonsModule[i + 1].id;
          } else {
            for (let x = 0; x < modules.length; x++) {
              const element = modules[x];
              if (idModule == element.id) {
                if (modules[x + 1]) {
                  this.nextModule = modules[x + 1].id;
                  this.nextLesson = "";
                } else {
                  this.nextModule = modules[x].id;
                  this.nextLesson = "";
                }
              }
            }
          }
          if (lessonsModule[i - 1]) {
            this.lastModule = idModule;
            this.lastLesson = lessonsModule[i - 1].id;
          } else {
            for (let x = 0; x < modules.length; x++) {
              const element = modules[x];
              if (idModule == element.id) {
                if (modules[x - 1]) {
                  this.lastModule = modules[x - 1].id;
                  this.lastLesson = "";
                } else {
                  this.lastModule = modules[x].id;
                  this.lastLesson = "";
                }
              }
            }
          }
        }
      }
      this.verifyModulesOPen();
    },
    async getLesson() {
      this.activePlayer = false;
      this.nextModule = "";
      this.nextLesson = "";
      this.lastModule = "";
      this.lastLesson = "";
      this.$forceUpdate();
      // busca os dados de uma aula
      let params = this.$route.params;
        if (params) {
          this.loadingPlayer = true;
          const urlApi = `/${params.curso}/module/${params.modulo}/lesson/${params.aula}`;
          await serviceCourse
            .read(urlApi)
            .then(async(resp) => {
              await this.getPermission();
              this.comentarios = []
              await this.getComment();
              this.apiLesson = resp;
              if (resp.mediaType === "quiz") {
                this.quiz = resp;
              }
    
              this.srcAula = resp.source;
              this.attachmentsLesson = resp.attachments.length;
              if (this.apiLesson.is_liberated === false) {
                this.lessonLiberation = this.apiLesson.liberated_at;
                if (
                  this.apiLesson.mediaType === "youtube" ||
                  this.apiLesson.mediaType === "audio"
                ) {
                  this.$root.$emit("dataLesson", null);
                } else {
                  this.vimeoVideoId = null;
                }
              } else {
                if (
                  this.apiLesson.mediaType === "youtube" ||
                  this.apiLesson.mediaType === "audio"
                ) {
                  setTimeout(() => {
                    this.$root.$emit("dataLesson", this.apiLesson);
                  }, 1000);
                }
                if (this.apiLesson.mediaType === "vimeo") {
                  this.setRegexVimeo();
                }
              }
    
              if (this.apiLesson.is_completed) {
                this.completedLesson = this.$t("player.concluida");
              } else {
                this.completedLesson = this.$t("player.marcar_concluida");
              }
              this.calculaPorcentagem();
              this.getNextAndPrev();
            })
            .catch((err) => console.log(err))
            .finally(() => {
              this.activePlayer = true;
              this.loadingPlayer = false;
            });
        }
    },
    async getComment(page = 1, isComment = false) {
      let idCourse = this.$route.params.curso;
      let idModule = this.$route.params.modulo;
      let idLesson = this.$route.params.aula;

        if (idModule !== undefined || idLesson !== undefined) {
          try {
            if (this.loadingComment) return;
              this.loadingComment = true;
              const resp = await serviceCourse.read(`${idCourse}/module/${idModule}/lesson/${idLesson}/comment?page=${page}`);
                if (resp.data.length === 0) {
                return;
              }
              if(isComment){
                this.comentarios = [];
              }
        this.comentarios = this.comentarios.concat(resp.data);
        this.comentarios = this.comentarios.filter(
                (item, index, opt) =>
                  index === opt.findIndex((t) => t.id === item.id)
              );
        this.totalPage = resp.last_page;    
        this.currentPage = resp.current_page;    
      } catch (error) {
        console.error('Erro ao buscar comentários:', error);
      } finally {
        this.loadingComment = false;
      }
    }
  },

  async handleScroll(event) {
  const tolerance = 5; 
  const bottomOfPage = event.target.scrollTop + event.target.clientHeight >= event.target.scrollHeight - tolerance;

  if (bottomOfPage && this.currentPage < this.totalPage && !this.loadingComment) {
    this.currentPage++;
    await this.getComment(this.currentPage);
  }
},
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
    debounce: debounce(function () {
      this.updateScroll();
    }, 100),
    getColor() {
      serviceMeta
        .search("keys[]=main_color")
        .then((resp) => {
          this.color = resp.main_color;
          if (this.color !== null) {
            const d = document;
            d.documentElement.setAttribute("data-color", this.color);
            document.documentElement.style.setProperty(
              "--maincolor",
              this.color
            );
            document.documentElement.style.setProperty(
              "--maincolorn",
              this.$func.convertColor(this.color, 210)
            );
            document.documentElement.style.setProperty(
              "--maincolortrans",
              this.color + "0f"
            );
          }
        })
        .catch((err) => console.error(err));
    },
    getLinkTelegram() {
      let idCourse = this.$route.params.curso;
      telegramService
        .read({id: `/link-telegram/${idCourse}`})
        .then((resp) => {
          this.telegramLink = resp.link;
          if (resp.link) {
            this.showTelegramLink = true;
          } else if (resp.liberated_at) {
            this.showTelegramLink = true;
          }
          this.$root.$emit("loadOff");
          // notify("sucesso", "Integração Telegram Iniciada!");
        })
        .catch((err) => {
          this.$root.$emit("loadOff");
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        });
    },
    getMemberPlan() {
      this.loadingMenu = true
      this.loadingPlayer = true
      serviceMember
        .read(`/member-plan`)
        .then(res => {
          this.memberPlanStatus = res.status
        })
        .catch(err => console.error(err))
        .finally(() => {
          this.loadingMenu = false
          this.loadingPlayer = false
        })
    }
  },
  // destroyed() {
  //   window.removeEventListener("scroll", this.scrollPosition);
  // },
  async mounted() {
    if (this.clubVersion === 'v2') {
      this.loadPlayerFromUrl();
    } else {
      this.getCurrentModule();
    }
    this.getThemeFixed();

    // Cookies.set("themeClub", this.theme, {expires: 365});
    // document.documentElement.setAttribute("data-theme", this.theme);
    await this.getPermission();
    this.getLinkTelegram();
    this.getColor();
    this.getPicProfile();
    await this.getMetaComent();
    this.getMetaDisableCommentsCourses()
    await this.getMemberPlan();

    document.body.style.backgroundImage = "";
    window.addEventListener("scroll", this.debounce);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();

    this.$root.$on("getComments", (data) => {
      //console.log(data);
      var coments = this.comentarios;
      this.comentarios = coments.filter((x) => x.id !== data);
      this.getComment();
      // console.log('primeira chamada');
    });
    this.$root.$on("liberaBtn", () => {
      this.blockLinks = false;
      //console.log("evento chegou no elemnto pai");
      this.actionToCompleteBlock();
    });
    this.$root.$on("calculaBtn", () => {
      // this.blockLinks = false;
      //console.log("evento chegou no elemnto pai");
      this.actionToCompleteBlock("next");
    });
    this.$root.$on("liberaBtnNext", () => {
      this.blockLinks = false;
      //console.log("evento chegou no pai next");
      this.pulaAulaProximo();
    });
    this.$root.$on("editComments", (data) => {
      this.editCommentId = data.id;
      this.meuComentario = data.comentario;
      this.textBtnComentar = this.$t("player.atualizar");
      var container = this.$el.querySelector("#myComment");
      container.scrollTop = container.scrollHeight;
    });
    await this.getComment(); 
  },
  watch: {
    meuComentario() {
      if (this.meuComentario) this.btnOk = false;
      else this.btnOk = true;
    },
    comentarios(val) {
      if (val) {
        this.scrollToComment();
      }
    }
  },
  async beforeRouteUpdate(to, from, next) {
    next();
  },
};
</script>
<style lang="scss">
#modal-liberated-lesson {
  z-index: 99999999999;

  .modal-header {
    border: none;
  }
}
</style>
<style scoped lang="scss">
.styleThumb {
  width: 70px;
  border-radius: 3px;
}

.notCompatible{
  color: #81858e;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 10px;
  p{
    text-align: center;
    font-size: 14px;
    font-weight: 500;
  }
}
.desativeComents {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #81858e;
  font-size: 14px;
}

.logox {
  width: 45px;
  height: 45px;
  margin-left: -9px;
  box-sizing: border-box;
  overflow: hidden;

  img {
    width: 100%;
    border-radius: 100%;
  }
}

a,
a:hover {
  text-decoration: none;
}

#modal-liberated-lesson {
  .allContentModal {
    padding: 0px 10px;
    margin: 0 auto;
    display: block;
    text-align: -webkit-center;
  }

  .spaceText2 {
    margin-bottom: 20px;
  }

  .spaceText1 {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  .text1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    letter-spacing: 0.2px;
    color: var(--fontcolor);
    text-align: center;
  }

  .text2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #818181;
  }

  .button {
    background: #000000;
    width: 215px;
    height: 55px;
    border-radius: 3px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 0.2px;
    color: #ffffff;
    border: none;
  }
}

.hide {
  display: none;
}

#myComment {
  color: var(--fontcolor) !important;
}

.svgTodoList {
  width: 12px;
  margin-left: 26px;
  transition: 0.5s;
  margin-top: 5px;
}

.spaceTitleModule {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.rotateSvg {
  transform: rotate(270deg);
}

.rotateSvg2 {
  transform: rotate(180deg);
}

.cancelaBtn {
  margin-right: 20px;
  background: #f7f7f7 !important;
  color: #81858e !important;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.invert {
  filter: invert(1);
}

.modoFoco {
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.644);
}

button {
  outline: none;
}

.container-aula {
  padding: 60px 44px 40px 48px;
  display: grid;
  grid-template-columns: 1fr 350px;
  word-break: break-word;
  gap: 30px;
  transition: 0.5s;
  position: relative;
}

.container-aula-mobile {
  padding: 20px 10px 10px 10px;
  width: 100%;
}

.container-aula.nav-close {
  grid-template-columns: 1fr 65px;
}

/* lado play */
.player {
  width: 100%;
  height: 65vh;
  background: #ededf000;
  border-radius: 5px;
  // div {
  //   width: 100% !important;
  //   height: 100% !important;
  // }

  &.isQuizV2 {
    height: auto;
    min-height: 65vh;
  }
}

.playerIframe {
  width: 100%;
  background: #ededf000;
  border-radius: 5px;

  div {
    width: 100% !important;
    height: 100%;
    position: relative;
  }
}

.playerMobile {
  width: 100%;
  // height: fit-content;
  background: #ededf000;
  border-radius: 5px;

  div {
    width: 100% !important;
    min-height: 250px;
    max-height: 250px;
  }
}

@media (max-width: 600px) {
  .playerMobile:not(.isQuizV2) {
    max-height: 250px !important;
  }
}

@media (min-width: 600px) and (max-width: 1025px) {
  .playerMobile:not(.isQuizV2) {
    max-height: 530px;
  }
}

.player iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.btnDownloadCertify {
  background: #00e4a0;
  border: 1px solid #00e4a0;
  box-sizing: border-box;
  box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
  border-radius: 5px;
  height: 55px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.controles-aula {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
  border-bottom: 0.5px solid #ededf0;
  margin-top: 25px;

  svg {
    margin-right: 10px;
    margin-left: 10px;

    path {
      fill: var(--maincolor) !important;
    }
  }
}

.controles-aula a {
  cursor: pointer;
}

.controles-aula-mobile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0;
  margin-top: 25px;

  svg {
    path {
      fill: var(--maincolor) !important;
    }
  }
}

.spaceDataMobile {
  margin-top: 30px;
  width: 100%;
}

.controles-aula-mobile button {
  width: 100%;
  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
  border: 1px solid var(--maincolor);
  color: var(--maincolor);
  background: transparent;
  transition: 0.3s;
}

.controles-aula a {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: var(--fontcolor);
  transition: 0.3s;
}

.slidebarMobile {
  float: right;
}

.controles-aula a:hover {
  color: var(--maincolor);
}

.controles-aula a:first-child img {
  margin-right: 15px;
}

.proxima-aula img {
  transform: rotate(180deg);
  margin-left: 15px;
}

.controles-aula button {
  width: 250px;
  /* height: 40px; */
  /* background: var(--maincolor); */

  color: #fff;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  padding: 15px;
  /* background: #e5e5e5; */
  border: 1px solid var(--maincolor);
  color: var(--maincolor);
  background: transparent;
  transition: 0.3s;
}

.customBar {
  height: 7px;

  div {
    height: 7px;
    background: var(--maincolor);
  }
}

.ql-align-justify {
  text-align: justify !important;
}

.ql-align-center {
  text-align: center !important;
}

.header-descricao {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.avaliacao,
.btn-header-descricao {
  display: flex;
  align-items: center;
}

.avaliacao p {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-right: 40px;
  color: var(--fontcolor) !important;
}

.apagar-luzes {
  margin-right: 25px;
}

.apagar-luzes button {
  width: 45px;
  height: 45px;
  background: #333333;
  margin: 0 auto;
  border: none;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  position: relative;
}

.arquivos-aula button {
  /* width: 187px; */
  padding: 15px 20px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 5px;
  font-weight: 600;
  font-size: 14px;
  /* estilo desabilitado */
  background: #ededf0;
  color: #81858e;
  pointer-events: none;
  transition: 0.3s;
}

.arquivos-aula button.enabled {
  background: #2133d2;
  color: #fff;
  pointer-events: all;
}

.arquivos-aula button.enabled:hover {
  background: #2133d2;
  color: #fff;
}

.arquivos-aula button.enabled img {
  filter: brightness(0) invert(1);
}

.arquivos-aula button img {
  margin-left: 15px;
}

.descricao {
  word-break: break-word;
  padding: 40px 0;
  // width: 10vw;
  border-bottom: 0.5px solid #ededf0;
  color: var(--fontcolor);
}

.descricao-mobile {
  padding: 40px 0;
}

.descricao-mobile p {
  color: #81858e;
  line-height: 1.5;
  font-size: 14px;
}

.descricao p {
  // width: 10vw;
  color: #81858e;
  line-height: 1.5;
  font-size: 14px;
}

.comentarios {
  padding: 40px 0;
}

.comentarios h5 {
  font-size: 16px;
  color: var(--fontcolor) !important;
}

.comentarios .meu-perfil {
  margin: 40px 0 30px 0;
  display: grid;
  grid-template-columns: 60px 1fr;
}

.meu-perfil .foto {
  width: 35px;
  height: 35px;
  background: var(--bordercolor);
  border-radius: 50%;
}

.meu-perfil .item {
  text-align: right;
  width: 90%;
}

.meu-perfil .item textarea {
  display: block;
  width: 100%;
  border: none;
  border-bottom: 0.5px solid #ededf0;
  padding: 5px 0;
  padding-right: 20px;
  font-size: 13px;
  background: var(--backgroundcolor);
}

.meu-perfil .item button {
  background: var(--maincolortrans);
  color: var(--maincolor);
  font-weight: 600;
  font-size: 13px;
  border: none;
  border-radius: 3px;
  padding: 12px 25px;
  margin-top: 25px;
}

.meu-perfil .item button:disabled {
  background: var(--disablebtn);
  color: #81858e;
}

.todos-comentarios {
  display: flex;
  flex-direction: column-reverse;
  /* background: silver; */
}

.item-comentario .meu-perfil {
  margin: 0;
}

.item-comentario + .item-comentario {
  margin: 17px 0;
}

.comentario-simples {
  text-align: initial !important;
}

.comentario-simples h6 {
  font-size: 13px;
}

.comentario-simples h6 span {
  color: #81858e;
  font-size: 12px;
  font-weight: normal;
  margin-left: 20px;
}

.comentario-simples p {
  color: #81858e;
  line-height: 1.5;
  margin: 10px 0 15px 0;
  font-size: 14px;
}

.comentario-simples a {
  font-size: 12px;
  font-weight: 600;
  color: #81858e;
}

/* lado navegação */
.nav-aulas {
  padding-left: 35px;
  border-left: 0.5px solid var(--bordercolor);
  overflow: hidden;
  max-height: 100vh;
  max-width: 380px;
  overflow: scroll;
  position: fixed;
  right: 0;
  top: 0;
  padding-bottom: 40px;
  padding-top: 40px;
  width: 100%;
}

.nav-aulas-mobile {
  padding-bottom: 40px;
  width: 100%;
  transition: 0.5s ease;
}

.container-aula.nav-close .nav-aulas {
  max-width: 80px;
}

.nav-aulas::-webkit-scrollbar {
  width: 17px;
  background: black;
}

.nav-aulas::-webkit-scrollbar-thumb {
  background: var(--backgroundcolor);
  border-left: 8.4px solid var(--backgroundcolor);
  border-right: 8.4px solid var(--backgroundcolor);
}

.nav-aulas::-webkit-scrollbar-track {
  background: var(--backgroundcolor);
}

.nav-aulas::-webkit-scrollbar-thumb {
  background: var(--backgroundcolor);
}

.nav-aulas::-webkit-scrollbar-thumb:hover {
  background: var(--backgroundcolor);
}

.nav-aulas::-webkit-scrollbar-track {
  background: var(--backgroundcolor);
}

.nav-aulas::-webkit-scrollbar-corner {
  background: var(--backgroundcolor);
}

.header-nav {
  display: flex;
  align-items: center;
  transition: 0.7s ease;
}

.header-nav button {
  border: none;
  background: transparent;
  padding: 5px 5px 5px 0;
  margin-right: 20px;
  outline: none;
  min-width: 20px;
}

.header-nav button img {
  /* filter: invert(50%); */
  transform: rotate(-90deg);
}

.container-aula.nav-close .nav-aulas .header-nav button img {
  transform: rotate(90deg);
}

.container-aula.nav-close .nav-aulas .header-nav span {
  font-size: 0;
}

.header-nav span {
  flex: 1;
  font-size: 16px;
  font-weight: 600;
  color: var(--fontcolor) !important;
}

.progresso {
  margin: 20px auto;
}

.progresso span {
  color: #81858e;
  font-size: 12px;
  font-weight: 600;
}

/* modulos e aulas */
.container-aula.nav-close .nav-aulas .listagem-curso .modulo_aula {
  border: none;
}

.listagem-curso {
  margin: 30px 0;
}

.modulo_aula {
  padding: 32px;
  border-radius: 5px;
  border: 0.5px solid var(--bordercolor);
  position: relative;
}

.modulo_aula + .modulo_aula {
  margin-top: 20px;
}

.modulo_aula.is_block ul li a,
.modulo_aula ul li.is_block a {
  opacity: 0.3;
  pointer-events: none;
}

.modulo_aula.is_block::before {
  content: "";
  width: 25px;
  height: 30px;
  display: inline-block;
  background: url("../assets/icons/block.svg") no-repeat;
  position: absolute;
  top: -15px;
  right: 0;
}

.modulo_aula h6 {
  color: #81858e;
  font-size: 14px;
  font-weight: 600;
}

.modulo_aula ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: 25px;
}

.modulo_aula ul li a {
  display: flex;
  align-items: center;
  justify-content: space-between;
  opacity: 1;
  pointer-events: all;
}

.modulo_aula ul li + li {
  margin-top: 15px;
}

.modulo_aula ul li a span:first-child {
  display: flex;
  align-items: center;
}

.modulo_aula ul li a span {
  font-size: 13px;
  font-weight: 600;
  color: #81858e;
}

.modulo_aula ul li a span:hover {
  color: var(--maincolor) !important;
}

.modulo_aula ul li a span:nth-child(2) {
  font-size: 12px;
}

.modulo_aula ul li a span svg {
  margin-right: 15px;
  filter: grayscale(1);
  width: 18px;
}

.modulo_aula ul li a span img.block-lesson {
  transform: rotate(0);
}

.completa {
  background: var(--maincolor) !important;
  color: #fff !important;
}

/* icone aula noa completa */
.modulo_aula ul li.lessonCompleta a span {
  color: #c4c4c4;
}

.lessonCompleta svg {
  filter: grayscale(0) !important;

  path {
    fill: var(--maincolor) !important;
  }
}

/* Aula exata */
.modulo_aula ul li a.router-link-exact-active span {
  color: var(--maincolor) !important;
}

.modulo_aula ul li a.router-link-exact-active svg {
  filter: var(--filtersvg);

  path {
    fill: var(--maincolor) !important;
  }
}

/* Avaliações */

/* Arquivos */
/*  */
.titulo-aula {
  font-size: 18px;
  color: var(--fontcolor);
  font-weight: bold;
  margin-bottom: 20px;
}

.titulo-aula-mobile {
  font-size: 14px;
  color: var(--maincolor);
  font-weight: bold;
  margin-bottom: 20px;
}

svg.checkedLesson {
  margin-left: 0px !important;
  margin-right: 14px !important;
  width: 13px;
  height: 12px;

  path {
    fill: white !important;
  }
}

.dataRestante {
  font-size: 13px;
  font-weight: 600;
  color: #81858e;
}

.textSubcategory {
  font-size: 14px;
  font-weight: 600;
  color: var(--maincolor);
  margin-bottom: 3px;
}

a.titleModule.router-link-active h6 {
  color: var(--maincolor);
}

.marginSubCategory {
  margin-left: 15px;
}

.moduleLiberation {
  font-size: 11px !important;
  color: #81858e !important;
  margin-top: -5px !important;
}

.spaceBottom {
  margin-bottom: 150px;
}

@media only screen and (min-device-width: 480px) and (max-device-width: 640px) and (orientation: landscape) {
  //enter code here
}

.playerMobile.positionbottom {
  position: fixed;
  height: 100px;
  width: 200px;
  right: 5px;
  bottom: 10px;
  z-index: 9999999;
}

.relativePosicao {
  position: relative !important;
}

// arquivos
.mobile-download {
  grid-template-columns: 1fr !important;
}

.grid-downloads {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: 30px 0 0 0;
  gap: 6px;
  list-style: none;
  padding: 0;

  p {
    margin: 0;
    margin-left: 15px;
    margin-right: 15px;
    height: 25px;
    overflow-y: auto;
    font-size: 13px;
  }

  p:first-child {
    margin-top: 15px;
    font-size: 13px;
    font-weight: 500;
  }

  p:nth-child(2) {
    color: var(--fontedowload) !important;
  }

  .baixar {
    height: 35px;
    position: absolute;
    cursor: pointer;
    width: 100%;

    svg {
      margin-right: 11px;
      margin-left: 15px;
    }

    p {
      font-weight: normal;
      font-size: 11px;
      color: var(--fontedowload);
      margin: 0;
    }

    display: flex;
    border-top: solid 0.5px var(--bordadowload);
    align-items: center;
  }

  li {
    border-radius: 5px;
    border: 1px solid var(--bordadowload);
    background: var(--fundodowload);
    position: relative;
    height: 100px;
  }
}

.info-panda-is-not-converted {
  justify-content: center !important;
  align-items: center !important;
  width: 100%;
  display: flex;
  border: 1px solid #f2f2f2;
  border-radius: 6px;
  color: #333;
  flex-direction: column;
  min-height: 300px;
  padding: 24px;
}

.ql-size-large {
  font-size: 1.5rem !important;
}

.video-player-container {
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  background: #000;
  border-radius: 6px;
}

.video-player-container-light {
  background: white !important;
}

.error-panda-dark {
  color: white !important;
}

.club-v2-container {
  justify-content: center;
  display: flex;
  width: 100%;
  background: var(--background-v2);
  min-height: 100vh;
  align-items: flex-start;
}

.lesson-title-container {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between
}
.fix-padding {
  padding-top: 0px;
  right: 0;
  left: 0;
  padding-right: 18px;
  padding-left: 18px;
}
@media screen and (min-width: 768px) {
  .fix-padding {
    padding-right: 0px;
    padding-left: 0px;
  }
  .club-v2-container {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.btn-back-home {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 35px;
  height: 35px;
  margin-left: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--background2-v2);
  svg {
    stroke: var(--fontcolor-v2);
  }
}
.star-rating-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: var(--fontcolor2-v2);
  padding-bottom: 44px;
  border-bottom: 1px dashed var(--bordercolor-v2);
  border-width: 2px;
}

.scroll-infinity {
  padding: 10px !important;
  overflow-y: auto !important;
  height: 600px !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
  &::-webkit-scrollbar {
    width: 0 !important;
    display: none;
  }
}
</style>
