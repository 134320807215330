<template>
  <div data-anima="top" class="comment_list_container">
    <div class="comment_list_item">
      <img v-if="comment.member.picture" :src="comment.member.picture" class="comment_list_icon" alt="list-picture" />
      <div v-else class="comment_list_icon" alt="list-picture">
        <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19 18V15.5798C19 14.2961 18.4996 13.0649 17.6088 12.1572C16.718 11.2495 15.5098 10.7395 14.25 10.7395H4.75C3.49022 10.7395 2.28204 11.2495 1.39124 12.1572C0.500445 13.0649 0 14.2961 0 15.5798V18" fill="url(#paint0_linear_3846_1071)"/>
          <path d="M9.5 9.68066C12.1234 9.68066 14.25 7.51357 14.25 4.84033C14.25 2.16709 12.1234 0 9.5 0C6.87665 0 4.75 2.16709 4.75 4.84033C4.75 7.51357 6.87665 9.68066 9.5 9.68066Z" fill="url(#paint1_linear_3846_1071)"/>
          <defs>
          <linearGradient id="paint0_linear_3846_1071" x1="9.5" y1="1.22727" x2="9.37315" y2="19.5211" gradientUnits="userSpaceOnUse">
          <stop stop-color="white"/>
          <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          <linearGradient id="paint1_linear_3846_1071" x1="9.5" y1="1.22727" x2="9.37315" y2="19.5211" gradientUnits="userSpaceOnUse">
          <stop stop-color="white"/>
          <stop offset="1" stop-color="white" stop-opacity="0"/>
          </linearGradient>
          </defs>
        </svg>
      </div>
      
      <div v-if="!getIsMobile" class="comment_list_content">
        <span class="name">{{ comment.member.name }}</span>
        <span class="classroom" v-if="comment.course_class_title">{{ comment.course_class_title }}</span>
        <span class="comment">{{ comment.comment }}</span>
      </div>
      <div v-else class="comment_list_content">
        <div class="nowrap-line">
          <span class="name">{{ comment.member.name }} </span>
          <div class="dot"></div> 
          <span class="comment">{{ moment(comment.created_at).lang($i18n.locale).fromNow("L") }}</span>
        </div>
        <span class="classroom" v-if="comment.course_class_title">{{ comment.course_class_title }}</span>
        <span class="comment break-text">{{ comment.comment }}</span>
      </div>
    </div>
    <div class="response_content_container">
      <div class="space_response"/>
      <div class="response_content">
        <svg @click="toggleLike(comment)" class="icon_like_svg" :class="{'icon_like_clicked': comment.liked}" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M5.06449 8.19995L8.31609 1C8.96287 1 9.58316 1.25285 10.0405 1.70294C10.4979 2.15302 10.7548 2.76347 10.7548 3.39998V6.59996H15.3558C15.5915 6.59734 15.8249 6.64517 16.0399 6.74014C16.2549 6.83511 16.4464 6.97495 16.601 7.14997C16.7557 7.325 16.8698 7.53102 16.9355 7.75376C17.0012 7.9765 17.017 8.21064 16.9816 8.43995L15.8598 15.6399C15.801 16.0214 15.6041 16.3692 15.3053 16.6191C15.0066 16.869 14.6261 17.0043 14.234 16.9999H5.06449M5.06449 8.19995V16.9999M5.06449 8.19995H2.6258C2.19461 8.19995 1.78108 8.36852 1.47618 8.66858C1.17129 8.96864 1 9.3756 1 9.79994V15.3999C1 15.8243 1.17129 16.2312 1.47618 16.5313C1.78108 16.8313 2.19461 16.9999 2.6258 16.9999H5.06449" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <div class="dot"></div>
        <span @click="show_reply_comment = comment.id, show_reply_comment2 = false" class="btn_response">{{ $t('player.answer') }}</span>
        <div v-if="!getIsMobile" class="dot"></div>
        <span v-if="!getIsMobile">{{ moment(comment.created_at).lang($i18n.locale).fromNow("L") }}</span>
      </div>
    </div>

    <!-- input resposta 1 -->
    <div class="reply-container">
      <!-- desktop -->
      <div v-if="show_reply_comment && !getIsMobile" class="input_container_resp">
        <input v-model="resp_comment" type="text" class="input_field_resp" autocomplete="off" :placeholder="`${$t('player.answer_for')} ${comment.member.name}`" name="name" id='name' />
        <button @click="sendComment" class="input_container_resp__button">
          <span class="mr-2 btn-comment-now" style="text-wrap: nowrap;">{{ $t("player.comment") }}</span>
          <!-- <svg class width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.33333 1L1 10.6H8.5L7.66667 17L16 7.4H8.5L9.33333 1Z" stroke-linecap="round" stroke="#333" stroke-linejoin="round"/>
          </svg> -->
        </button>
      </div>
      <!-- mobile -->
      <div v-if="(show_reply_comment && show_reply_comment == comment.id) && getIsMobile" class="input_container_resp_mobile">
        <input v-model="resp_comment" type="text" class="input_field_resp_mobile" autocomplete="off" :placeholder="`${$t('player.answer_for')} ${comment.member.name}`" name="name" id='name' />
        <button @click="sendComment" class="input_container_resp__button_mobile">
          <svg class="icon_send_mobile" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.6066 8.6066H3.93934M15.6066 8.6066L1.28769 15.5009L3.93934 8.6066M15.6066 8.6066L1.28769 1.71231L3.93934 8.6066" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
    </div>

    <div data-anima="bottom" v-if="!show_replies && comment.replies && comment.replies.length" @click="show_replies = true" class="see_response_container">
      <div class="space_response"/>
      
      <div class="space_see_response"/>
      <div class="see_response_content ml-2">
        <span class="comment">
          Ver {{ comment.replies.length || 0 }} respostas
        </span>
      </div>
    </div>
    <div data-anima="bottom" v-if="show_replies && comment.replies && comment.replies.length" @click="show_replies = false" class="see_response_container">
      <div class="space_response"/>
      
      <div class="space_see_response"/>
      <div class="see_response_content ml-2">
        <span class="comment">
          Ocultar respostas
        </span>
      </div>
    </div>

    <!-- respostas -->
    <div data-anima="top" class="comment_list_container_replies" v-if="comment.replies && show_replies">
      <div v-for="reply in comment.replies" :key="`reply-${reply.id}`" class="comment_list_item_reply">
        <div class="comment_list_item">
          <img v-if="reply.member.picture" :src="reply.member.picture" class="comment_list_icon" alt="list-picture" />
          <div v-else class="comment_list_icon" alt="list-picture">
            <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M19 18V15.5798C19 14.2961 18.4996 13.0649 17.6088 12.1572C16.718 11.2495 15.5098 10.7395 14.25 10.7395H4.75C3.49022 10.7395 2.28204 11.2495 1.39124 12.1572C0.500445 13.0649 0 14.2961 0 15.5798V18" fill="url(#paint0_linear_3846_1071)"/>
              <path d="M9.5 9.68066C12.1234 9.68066 14.25 7.51357 14.25 4.84033C14.25 2.16709 12.1234 0 9.5 0C6.87665 0 4.75 2.16709 4.75 4.84033C4.75 7.51357 6.87665 9.68066 9.5 9.68066Z" fill="url(#paint1_linear_3846_1071)"/>
              <defs>
              <linearGradient id="paint0_linear_3846_1071" x1="9.5" y1="1.22727" x2="9.37315" y2="19.5211" gradientUnits="userSpaceOnUse">
              <stop stop-color="white"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint1_linear_3846_1071" x1="9.5" y1="1.22727" x2="9.37315" y2="19.5211" gradientUnits="userSpaceOnUse">
              <stop stop-color="white"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
              </linearGradient>
              </defs>
            </svg>
          </div>
          <div v-if="!getIsMobile" class="comment_list_content">
            <span class="name">{{ reply.member.name }}:</span>
            <span class="comment">{{ reply.comment }}</span>
          </div>
          <div v-if="getIsMobile" class="comment_list_content">
            <div class="nowrap-line">
              <span class="name">{{ reply.member.name }} </span>
              <div class="dot"></div> 
              <span class="comment">{{ moment(reply.created_at).lang($i18n.locale).fromNow("l") }}</span>
            </div>
            <span class="comment break-text ">{{ reply.comment }}</span>
          </div>
        </div>
        <div class="response_content_container">
          <div class="space_response"/>
          <div class="response_content">
            <svg @click="toggleLike(reply)" class="icon_like_svg" :class="{'icon_like_clicked': reply.liked}" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.06449 8.19995L8.31609 1C8.96287 1 9.58316 1.25285 10.0405 1.70294C10.4979 2.15302 10.7548 2.76347 10.7548 3.39998V6.59996H15.3558C15.5915 6.59734 15.8249 6.64517 16.0399 6.74014C16.2549 6.83511 16.4464 6.97495 16.601 7.14997C16.7557 7.325 16.8698 7.53102 16.9355 7.75376C17.0012 7.9765 17.017 8.21064 16.9816 8.43995L15.8598 15.6399C15.801 16.0214 15.6041 16.3692 15.3053 16.6191C15.0066 16.869 14.6261 17.0043 14.234 16.9999H5.06449M5.06449 8.19995V16.9999M5.06449 8.19995H2.6258C2.19461 8.19995 1.78108 8.36852 1.47618 8.66858C1.17129 8.96864 1 9.3756 1 9.79994V15.3999C1 15.8243 1.17129 16.2312 1.47618 16.5313C1.78108 16.8313 2.19461 16.9999 2.6258 16.9999H5.06449" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <div class="dot"></div>
            <span @click="replyToInfinite(comment, reply), show_reply_comment = false" class="btn_response">{{ $t('player.answer') }}</span>
            <div v-if="!getIsMobile" class="dot"></div>
            <span v-if="!getIsMobile">{{ moment(reply.created_at).lang($i18n.locale).fromNow("l") }}</span>
          </div>
        </div>
      </div>
       <!-- resposta da resposta -->
       <div class="reply-container respComment2">
        <!-- //input desktop -->
        <div v-if="(show_reply_comment2 && show_reply_comment2 === comment.id) && !getIsMobile" class="input_container_resp">
          <input ref="respInput2" v-model="resp_comment" type="text" class="input_field_resp resp_comment_input" autocomplete="off" :placeholder="`${$t('player.answer')}`" name="name" id='name' />
          <button @click="sendComment2" class="input_container_resp__button">
            <span class="mr-2 btn-comment-now" style="text-wrap: nowrap;">{{ $t("player.comment") }}</span>
            <!-- <svg class="icon_comment_resp" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M9.33333 1L1 10.6H8.5L7.66667 17L16 7.4H8.5L9.33333 1Z" stroke-linecap="round" stroke="#333" stroke-linejoin="round"/>
            </svg> -->
          </button>
        </div>

        <!-- //input mobile -->
        <div v-if="(show_reply_comment2 && show_reply_comment2 === comment.id) && getIsMobile" class="input_container_resp_mobile">
          <input ref="respInput2" v-model="resp_comment" type="text" class="input_field_resp_mobile resp_comment_input" autocomplete="off" :placeholder="`${$t('player.answer')}`" name="name" id='name' />
          <button @click="sendComment2" class="input_container_resp__button_mobile">
            <svg class="icon_send_mobile" width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.6066 8.6066H3.93934M15.6066 8.6066L1.28769 15.5009L3.93934 8.6066M15.6066 8.6066L1.28769 1.71231L3.93934 8.6066" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </div>
      <div v-if="show_replies && comment.replies && comment.replies.length > 10" @click="show_replies = false" class="see_response_container">
        <div class="space_see_response"/>
        <div class="see_response_content ml-2">
          <span class="comment">
            Ocultar respostas
          </span>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import moment from 'moment';
import {mapActions, mapGetters} from "vuex";
export default {
  props: {
    comment: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      moment: moment,
      resp_comment: "",
      show_reply_comment: false,
      show_reply_comment2: false,
      show_replies: false,
    }
  },
  computed: {
    ...mapGetters(['getIsMobile'])
  },
  methods: {
    ...mapActions(['makeAComment', 'like', 'dislike']),
    replyToInfinite(reply, reply2) {
      this.show_reply_comment = false;
      this.show_reply_comment2 = reply.id;
      this.resp_comment = `@${reply2.member.name.toLowerCase().split(' ')[0]} `;

      const el0 = document.getElementsByClassName('respComment2')[0];
      el0.scrollIntoView({ behavior: 'smooth', block: 'start' });

      const el = document.getElementsByClassName('resp_comment_input')[0];
      if (el) {
        el.focus();
      }
    },
    sendComment() {
      if (this.resp_comment.length) {
        this.makeAComment({ parent_lesson_comment_id: this.comment.id, comment: this.resp_comment });
      }
      setTimeout(() => {
        this.resp_comment = "";
        this.show_reply_comment = false;
      }, 1000);
    },
    sendComment2() {
      if (this.resp_comment.length) {
        this.makeAComment({ parent_lesson_comment_id: this.show_reply_comment2, comment: this.resp_comment });
      }
      setTimeout(() => {
        this.resp_comment = "";
        this.show_reply_comment2 = false;
      }, 1000);
    },
    toggleLike(comment) {
      const isLike = !comment.liked;
      if (isLike) {
        this.like(comment.id);
      } else {
        this.dislike(comment.id);
      }
    }
  }
}
</script>

<style>
.see_response_container {
  display: flex;
  width: 100%;
  flex-wrap: nowrap;
  height: 30px;
  align-items: center;
  cursor: pointer;
  margin-top: 12px;
}
.space_see_response {
  width: 30px;
  height: 1px;
  border-bottom: 1px solid var(--bordercolor-v2)
}
.input_container_resp_mobile {
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  border-radius: 50px;
  padding-right: 20px;
  margin-top: 6px;
}

.input_field_resp_mobile {
  padding-left: 20px;
  display: flex;
  align-items: center;
  height: 40px;
  border-radius: 50px;
  width: 100%;
  background-color: var(--background2-v2) !important;
  color: var(--fontcolor-v2);
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  margin-right: 6px;
}
.input_container_resp__button_mobile {
  background-color: var(--background2-v2) !important;
  border: none;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
}
.icon_send_mobile {
  stroke: var(--fontcolor-v2)
}
.nowrap-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.input_container_resp {
  width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  border-radius: 50px;
  margin-top: 20px;
  background: var(--bordercolor-v2);
}
.reply-container {
  display: flex;
  width: 100%;
  padding-left: 60px;
  padding-right: 50px;
}
.comment_list_container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  padding: 20px;
  padding-right: 0px;
  background: var(--background2-v2) !important;
  border-radius: 21px;
}
.comment_list_container_replies {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 0px;
  padding-bottom: 20px;
  padding-left: 60px;
  padding-right: 0px;
  background: var(--background2-v2) !important;
  border-radius: 21px;
}
.comment_list_item {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  color: var(--fontcolor-v2) !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
.comment_list_item_reply {
  flex-wrap: wrap;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  color: var(--fontcolor-v2) !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  margin-top: 20px;
  line-height: 17px;
}

.response_content_container {
  display: flex;
  flex-wrap: nowrap;
  margin-top: 20px;
}
.response_content {
  flex-wrap: nowrap;
  display: flex;  
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: var(--fontcolor2-v2) !important;
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
}
.comment_list_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--maincolor);
}
.space_response {
  width: 90px;
}
.comment_list_content {
  font-family: 'Montserrat';
  font-style: normal;
  display: flex;
  flex-direction: column;
  line-height: 150%;
  max-width: 90% !important;
}
.name {
  font-weight: 600;
  font-size: 14px;
  color: var(--fontcolor5-v2);
  margin-right: 3px;
}
.comment {
  font-weight: 400;
  font-size: 14px;
  color: var(--fontcolor5-v2);
  word-wrap: break-word;
}
.classroom {
  font-weight: 400;
  font-size: 12px;
  color: var(--fontcolor4-v2);
  margin-right: 3px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
}
.dot {
  width: 3px;
  height: 3px;
  margin-right: 12px;
  margin-left: 12px;
  border-radius: 50%;
  background: var(--fontcolor2-v2);
}
.btn_response {
  cursor: pointer;
}
.input_field_resp {
  padding-left: 20px;
  padding-right: 100px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  width: 100%;
  background-color: var(--background2-v2);
  background: var(--background2-v2);
  color: var(--fontcolor-v2);
  border: none;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
.input_field_resp::placeholder {
  color: var(--fontcolor2-v2);
}
.input_container_resp__button {
  background-color: var(--maincolor) !important;
  border: none;
  color: #333;
  padding-left: 20px;
  width: 151px;
  right: 20px;
  position: absolute;
  padding-right: 26px;
  display: flex;
  align-items: center;
  height: 50px;
  border-radius: 50px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
}
.btn-comment-now {
  color: #333 !important;
}
.icon_like_svg {
  stroke: var(--fontcolor2-v2);
  height: 14px;
  cursor: pointer;
}
.icon_like_clicked {
  stroke: var(--maincolor) !important;
}
@media screen and (max-width: 768px) {
  .comment_list_icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 6px;
  }
  .space_response {
    width: 40px;
  }
  .comment_list_content {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .comment_list_container_replies {
    padding-left: 35px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0px;
    padding-bottom: 20px;
    padding-right: 0px;
    background: var(--background-v2) !important;
    border-radius: 21px;
  }
  .comment_list_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    background: var(--background-v2) !important;
  }
  .input_field_resp {
    padding-left: 20px;
    padding-right: 100px;
    display: flex;
    align-items: center;
    height: 50px;
    border-radius: 50px;
    width: 100%;
    background-color: var(--background2-v2);
    background: var(--background2-v2);
    color: var(--fontcolor-v2);
    border: none;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 17px;
  }
  .reply-container {
    display: flex;
    width: 100%;
    padding-left: 30px;
    padding-right: 6px;
  }
  .name {
    font-weight: 600;
    font-size: 13px;
    color: var(--fontcolor4-v2);
    margin-right: 3px;
  }
  .comment {
    font-weight: 400;
    font-size: 13px;
    word-wrap: break-word;
    color: var(--fontcolor4-v2);
  }
  .classroom {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: var(--fontcolor4-v2);
    margin-right: 3px;
    display: flex;
    align-items: center;
    padding-bottom: 5px;
  }
  
}
.break-text {
  white-space: pre-wrap !important;
  word-break: break-all !important;
}
</style>